// src/components/gebaeudeDaten.js
import holzfaeller from '../images/lamberjackImage.webp';
import steinbruch from '../images/stonemasonImage.webp';
import farm from '../images/farmImage.webp';
import brunnen from '../images/drawingWellImage.webp';
import kohlemine from '../images/coalMineImage.webp';
import erzmine from '../images/goldMineBuildingImage.webp';
import wohnhaus from '../images/houseImage.webp';
import lager from '../images/warehouseImage.webp';
import markt from '../images/merchantBuildingImage.webp';
import fort from '../images/barracksBuildingImage.webp';
import marine from '../images/ShipyardBuildingImage.webp';
import kaserne from '../images/fortBuildingImage.webp';
import brewery from '../images/breweryImage.webp';
import samwill from '../images/samwill.webp';
import sciencehous from '../images/scienceBuildingImage.webp';
import hops from '../images/hopsImage.webp';
import furniture from '../images/furnitureImage.webp';

export const gebaeudeDaten = {
  economy: [ 
    {
      name: 'lumberjack', 
      bild: holzfaeller,
      typ: 'economy',
      beschreibungKey: 'GebaeudeBeschreibungen.lumberjack',      
      produktionBasis: 0.025, //Produziert Rohstoff
      faktorProduktion: 0.55, //Faktor um welchen das Gebäude pro Level verbesser
      kostenBasis: {      //Kostenpunkt für das Gebäude in der ersten Stuffe
        holz: 50,
        stein: 20,
        nahrung: 20,
        wasser: 25,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1, // Bevölkerungskosten pro zugewiesenem Arbeiter
      faktorKosten: 0.8, //Gebäudekosten werden um diesen Faktor gesteigert
      bauzeitBasis: 5, // Level 2 dauert 5 Sekunden
      faktorBauzeit: 0.5, // Bauzeit steigt pro Level um 50%
      maxArbeiter: 5, // stellt die Maximale Anzahl der Arbeiter pro Level ein
      faktorArbeiter: 0.1, //erhöht die maxArbeiter um diesen Faktor mit jedem Stufenaufstieg
    },
    {
      /*sollte bretter herstellen für ziviles gebäude "Möbelmacher"
      sollte Holz nutzen um bretter herzustellen
      vlt ab lumberjack lvl 4 freischalten damit der spieler von level 3 auf 4
      Möbelherstellen muss*/ 
      name: 'bretterbude', 
      bild: samwill,  
      typ: 'economy', 
      beschreibungKey: 'GebaeudeBeschreibungen.bretterbude',      
      produktionBasis: 0.02, 
      faktorProduktion: 0.55, 
      kostenBasis: {
        holz: 300,
        stein: 200,
        nahrung: 120,
        wasser: 225,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.85, 
      bauzeitBasis: 25, 
      faktorBauzeit: 0.5, 
      maxArbeiter: 5, 
      faktorArbeiter: 0.1,
      unlockLevel: 4, // Level, ab dem es sichtbar ist
      requiredBuilding: 'lumberjack', // Name der Gebäude, ab der freigeschaltet wird
      verbrauch: { // Neu hinzugefügt**
        holz: 0.5, // Verbraucht 1 Holz pro Sekunde
      },
      produkt: 'bretter', // Produziert 'bretter'
    },
    {
      name: 'stonemason', 
      bild: steinbruch,
      typ: 'economy',
      beschreibungKey: 'GebaeudeBeschreibungen.stonemason',      
      produktionBasis: 0.018,
      faktorProduktion: 0.5,
      kostenBasis: {
        holz: 35,
        stein: 60,
        nahrung: 50,
        wasser: 60,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.7,
      bauzeitBasis: 10, 
      faktorBauzeit: 1.0, 
      maxArbeiter: 6,
      faktorArbeiter: 0.1,
      unlockLevel: 1, // Level, ab dem es sichtbar ist
      requiredBuilding: 'lumberjack', // Name der Gebäude, ab der freigeschaltet wird
    },
    {
      name: 'farm', 
      bild: farm,
      typ: 'economy',
      beschreibungKey: 'GebaeudeBeschreibungen.farm',      
      produktionBasis: 0.3,
      faktorProduktion: 0.54,
      kostenBasis: {
        holz: 30,
        stein: 20,
        nahrung: 10,
        wasser: 40,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.6,
      bauzeitBasis: 20, 
      faktorBauzeit: 1.5, 
      maxArbeiter: 10,
      faktorArbeiter: 0.1,
    },
    /* Ab Farm Level 4 hopfen für brauerei*/
    {
      name: 'hopfenfarm', 
      bild: hops,
      typ: 'economy',
      beschreibungKey: 'GebaeudeBeschreibungen.hopfenfarm',      
      produktionBasis: 0.5,
      faktorProduktion: 0.54,
      kostenBasis: {
        holz: 30,
        stein: 20,
        nahrung: 100,
        wasser: 200,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.6,
      bauzeitBasis: 20, 
      faktorBauzeit: 1.5, 
      maxArbeiter: 5,
      faktorArbeiter: 0.1,
      unlockLevel: 5,
      requiredBuilding: 'farm',
    },
    {
      name: 'drawingWell', 
      bild: brunnen,
      typ: 'economy',
      beschreibungKey: 'GebaeudeBeschreibungen.drawingWell',      
      produktionBasis: 0.4,
      faktorProduktion: 0.4,
      kostenBasis: {
        holz: 20,
        stein: 30,
        nahrung: 20,
        wasser: 10,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.5,
      bauzeitBasis: 8, 
      faktorBauzeit: 0.8,
      maxArbeiter: 6,
      faktorArbeiter: 0.1,
    },
    {
      name: 'coalMine', 
      bild: kohlemine,
      typ: 'economy',
      beschreibungKey: 'GebaeudeBeschreibungen.coalMine',      
      produktionBasis: 0.015,
      faktorProduktion: 0.45,
      kostenBasis: {
        holz: 60,
        stein: 70,
        nahrung: 50,
        wasser: 60,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.8,
      bauzeitBasis: 15, 
      faktorBauzeit: 1.2,
      maxArbeiter: 20,
      faktorArbeiter: 0.1,
      unlockLevel: 3, // Level, ab dem es sichtbar ist
      requiredBuilding: 'lumberjack', // Name der Gebäude, ab der freigeschaltet wird
    },
    {
      name: 'erzmine', 
      bild: erzmine,
      typ: 'economy',
      beschreibungKey: 'GebaeudeBeschreibungen.erzmine',      
      produktionBasis: 0.005,
      faktorProduktion: 0.3,
      kostenBasis: {
        holz: 80,
        stein: 90,
        nahrung: 60,
        wasser: 70,
        kohle: 50,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.8,
      bauzeitBasis: 25, 
      faktorBauzeit: 1.5,
      maxArbeiter: 30,
      faktorArbeiter: 0.1,
      unlockLevel: 5, // Level, ab dem es sichtbar ist
      requiredBuilding: 'lumberjack', // Name der Gebäude, ab der freigeschaltet wird
    },
  ],
  civil: [ 
    {
      name: 'house', 
      bild: wohnhaus,
      typ: 'civil',
      beschreibungKey: 'GebaeudeBeschreibungen.house',      
      kostenBasis: {
        holz: 100,
        stein: 100,
        nahrung: 50,
        wasser: 50,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      faktorKosten: 0.7,
      bauzeitBasis: 12,
      faktorBauzeit: 1.0,
    },
    {
      name: 'warehouse', 
      bild: lager,
      typ: 'civil',
      beschreibungKey: 'GebaeudeBeschreibungen.warehouse',      
      kostenBasis: {
        holz: 150,
        stein: 150,
        nahrung: 75,
        wasser: 75,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.7,
      bauzeitBasis: 18,
      faktorBauzeit: 1.2,
      lagerKapazitaetBasis: {
        holz: 1000,
        stein: 800,
        nahrung: 1500,
        wasser: 1000,
        kohle: 800,
        metall: 500,
        bretter: 100,
        hopfen: 100,
        furniture: 100,
        beer: 100,
      },
      faktorLagerKapazitaet: 0.4,
      unlockLevel: 2, // Level, ab dem es sichtbar ist
      requiredBuilding: 'house', // Name der Gebäude, ab der freigeschaltet wird
    },
    {
      name: 'sciencehous', 
      bild: sciencehous,
      typ: 'civil',
      beschreibungKey: 'GebaeudeBeschreibungen.sciencehous',      
      kostenBasis: {
        holz: 550,
        stein: 850,
        nahrung: 575,
        wasser: 975,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.8,
      bauzeitBasis: 120,
      faktorBauzeit: 1.2,
      maxArbeiter: 5,
      faktorArbeiter: 0.1,
      unlockLevel: 5, // Level, ab dem es sichtbar ist
      requiredBuilding: 'house', // Name der Gebäude, ab der freigeschaltet wird
    },
    {
      name: 'furniture',
      bild: furniture,
      typ: 'civil',
      beschreibungKey: 'GebaeudeBeschreibungen.furniture',
      produktionBasis: 1,
      faktorProduktion: 0.5,
      kostenBasis: {
        holz: 500,
        stein: 300,
        nahrung: 200,
        wasser: 150,
        bretter: 100,
      },
      buildingPopulationCost: 3,
      workerPopulationCost: 1,
      faktorKosten: 0.9,
      bauzeitBasis: 40,
      faktorBauzeit: 0.6,
      maxArbeiter: 5,
      faktorArbeiter: 0.1,
      unlockLevel: 2,
      requiredBuilding: 'bretterbude',
      verbrauch: {
        bretter: 1, // Verbraucht 1 Bretter pro Sekunde
      },
      produkt: 'furniture', // Produziert 'furniture'
    },
    {
      /*Nutzt Hopfen um Bier herzustellen, sollte ab */ 
      name: 'brewery', 
      bild: brewery,
      typ: 'civil',
      beschreibungKey: 'GebaeudeBeschreibungen.brewery',      
      produktionBasis: 0.04,
      faktorProduktion: 0.3,
      kostenBasis: {
        holz: 220,
        stein: 190,
        nahrung: 60,
        wasser: 700,
        kohle: 50,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.8,
      bauzeitBasis: 25, 
      faktorBauzeit: 1.5,
      maxArbeiter: 3,
      faktorArbeiter: 0.1,
      unlockLevel: 5,
      requiredBuilding: 'farm',
      verbrauch: { // Neu hinzugefügt**
        hopsen: 0.6, // Verbraucht 1 Holz pro Sekunde
        wasser: 0.5
      },
      produkt: 'beer', // Produziert 'bretter'
    },
    {
      name: 'market', 
      bild: markt,
      typ: 'civil',
      beschreibungKey: 'GebaeudeBeschreibungen.market',      
      kostenBasis: {
        holz: 200,
        stein: 200,
        nahrung: 100,
        wasser: 100,
        metall: 50,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.8,
      bauzeitBasis: 30,
      faktorBauzeit: 1.5,
      unlockLevel: 3, // Level, ab dem es sichtbar ist
      requiredBuilding: 'house', // Name der Gebäude, ab der freigeschaltet wird
    },
  ],
  military: [ 
    {
      name: 'barracks', 
      bild: kaserne,
      typ: 'military',
      beschreibungKey: 'GebaeudeBeschreibungen.barracks',      
      kostenBasis: {
        holz: 250,
        stein: 250,
        nahrung: 150,
        wasser: 150,
        metall: 100,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.8,
      bauzeitBasis: 35,
      faktorBauzeit: 1.3,
      unlockLevel: 5, // Level, ab dem es sichtbar ist
      requiredBuilding: 'house', // Name der Gebäude, ab der freigeschaltet wird
    },
    {
      name: 'fort', 
      bild: fort,
      typ: 'military',
      beschreibungKey: 'GebaeudeBeschreibungen.fort',      
      kostenBasis: {
        holz: 300,
        stein: 300,
        nahrung: 200,
        wasser: 200,
        metall: 150,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.9,
      bauzeitBasis: 45,
      faktorBauzeit: 1.5,
      unlockLevel: 2, // Level, ab dem es sichtbar ist
      requiredBuilding: 'barracks', // Name der Gebäude, ab der freigeschaltet wird
    },
    {
      name: 'marine', 
      bild: marine,
      typ: 'military',
      beschreibungKey: 'GebaeudeBeschreibungen.marine',      
      kostenBasis: {
        holz: 350,
        stein: 350,
        nahrung: 250,
        wasser: 250,
      },
      buildingPopulationCost: 2, // Bevölkerungskosten für den Bau des Gebäudes
      workerPopulationCost: 1,
      faktorKosten: 0.9,
      bauzeitBasis: 50,
      faktorBauzeit: 1.5,
      unlockLevel: 2, // Level, ab dem es sichtbar ist
      requiredBuilding: 'fort', // Name der Gebäude, ab der freigeschaltet wird
    },
  ],
};
