// src/Login.js

import React, { useState } from 'react';
import { getAddress } from 'ethers';
import { saveWalletAddress } from './components/SaveWallet';
import Game from './Game';
import './Login.css';
import { useTranslation } from 'react-i18next';
import Notification from './components/Notification'; // Importiere die Notification-Komponente

function Login() {
  const { t } = useTranslation();  // i18next Hook zur Verwendung der Übersetzungen
  const [walletAddress, setWalletAddress] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [notification, setNotification] = useState(null); // State für Benachrichtigungen

  const connectWallet = async () => {
    setIsLoading(true);
    try {
      if (!window.ethereum) {
        setNotification({
          message: t('error.noMetaMask'),
          type: 'error',
        });
        setIsLoading(false);
        return;
      }
  
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
  
      const address = getAddress(accounts[0]);
      setWalletAddress(address);
  
      const result = await saveWalletAddress(address);
      if (result.success) {
        setIsLoggedIn(true);
        setNotification({
          message: result.message || t('login.success'),
          type: 'success',
        });
      } else {
        setNotification({
          message: result.message || t('error.saveWallet'),
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Fehler beim Verbinden mit der Wallet:', error);
      setNotification({
        message: t('error.walletConnect'),
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Game walletAddress={walletAddress} />;
  }

  return (
    <div className="login-container">
      <h1 className="login-title">{t('login.title')}</h1>  
      {walletAddress ? (
        <p className="connected-address">{t('login.connectedWith')} {walletAddress}</p>
      ) : (
        <button
          className="login-button"
          onClick={connectWallet}
          disabled={isLoading}
        >
          <div className="button-content">
            {isLoading && <div className="spinner"></div>}
            {t('login.connect')}
          </div>
        </button>
      )}
      {/* Zeige die Notification-Komponente an */}
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      <div className="description">
        <p>{t('login.description1')}</p>
        <p>{t('login.description2')}</p>
        <p>{t('login.description3')}</p>
        <p>{t('login.description4')}</p>
        <p>{t('login.description5')}</p>
      </div>
    </div>
  );
}

export default Login;
