// src/components/BuyBauslot.js

import Web3 from 'web3';
import { CONTRACT_ADDRESS, CONTRACT_ABI } from '../wallet/contractData';

export const buyBauslot = async (walletAddress) => {
  try {
    if (window.ethereum) {
      console.log('Ethereum Provider gefunden.');
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const web3 = new Web3(window.ethereum);
      console.log('Web3 Instanz erstellt.');

      const gamePaymentContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
      console.log('Smart Contract instanziiert:', gamePaymentContract.options.address);

      // Betrag für den Kauf (1 IOTA in Ether umgerechnet, falls notwendig)
      const purchaseAmountInWei = web3.utils.toWei('10', 'ether'); // Passe dies an, falls du eine andere Einheit verwendest

      // Gaspreis und Gaslimit schätzen
      const gasPrice = await web3.eth.getGasPrice();
      console.log(`Aktueller Gaspreis: ${gasPrice}`);

      const gasEstimate = await gamePaymentContract.methods.buyBauslot().estimateGas({
        from: walletAddress,
        value: purchaseAmountInWei,
      });
      console.log(`Geschätztes Gaslimit: ${gasEstimate}`);

      // Transaktion senden, um den Bauslot zu kaufen
      console.log('Kaufe Bauslot über den Smart Contract...');
      const tx = await gamePaymentContract.methods.buyBauslot().send({
        from: walletAddress,
        value: purchaseAmountInWei,
        gas: gasEstimate,
        gasPrice: gasPrice,
      });
      console.log('Bauslot erfolgreich gekauft:', tx);

      // Erfolgsnachricht zurückgeben
      return { success: true };
    } else {
      throw new Error('Kein Ethereum-Provider gefunden');
    }
  } catch (error) {
    console.error('Fehler beim Kauf des Bauslots:', error);

    let errorMessage = 'Fehler beim Kauf des Bauslots. Bitte versuche es erneut.';

    // Überprüfe, ob der Fehler ein "Insufficient Funds" Fehler ist
    if (error.code === -32000 && error.message.includes('insufficient funds')) {
      errorMessage = 'Du hast nicht genügend IOTA, um diesen Bauslot zu kaufen.';
    } else if (error.message.includes('User denied transaction signature')) {
      errorMessage = 'Transaktion abgelehnt.';
    }

    // Fehlernachricht zurückgeben
    return { success: false, message: errorMessage };
  }
};
