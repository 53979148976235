// src/components/SaveGame.js
import Web3 from 'web3';
import { CONTRACT_ADDRESS, CONTRACT_ABI, LEADERBOARD_ADDRESS, LEADERBOARD_ABI } from '../wallet/contractData';

export const saveGame = async (
  walletAddress,
  resources,
  gebaeude,
  punkte,
  resourcenAusgegeben,
  lastLogin,
  aktuelleBevoelkerung,
  genutzteBevoelkerung,
  maximaleBevoelkerungskapazitaet,
  bevoelkerungswachstumProStunde,
  bauslots,
  wirtschaftPunkte, 
  militaerPunkte,
  resourcenAusgegebenWirtschaft, 
  resourcenAusgegebenMilitaer 
) => {
  try {
    if (window.ethereum) {
      console.log('Ethereum Provider gefunden.');
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const web3 = new Web3(window.ethereum);
      console.log('Web3 Instanz erstellt.');

      const gamePaymentContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
      console.log('GamePayment Smart Contract instanziiert:', gamePaymentContract.options.address);

      // Skalierungsfaktoren
      const SCALING_FACTOR = 100; // Für Ressourcen und Produktionen
      const POPULATION_SCALING_FACTOR = 100; // Für Bevölkerungswerte
      const GROWTH_RATE_SCALING_FACTOR = 1000; // Für Wachstumsraten

      // Vorbereitung der Schlüssel und Werte für Ressourcen
      const resourceKeys = Object.keys(resources);
      const resourceValues = resourceKeys.map(key => Math.floor(resources[key] * SCALING_FACTOR));

      // Vorbereitung der Schlüssel und Werte für Gebäude
      const gebaeudeKeys = Object.keys(gebaeude);
      const gebaeudeLevels = gebaeudeKeys.map(key => Math.floor(gebaeude[key].level || 0));
      const gebaeudeIsConstructing = gebaeudeKeys.map(key => gebaeude[key].isConstructing || false);
      const gebaeudeConstructionEndTimes = gebaeudeKeys.map(key => Math.floor((gebaeude[key].constructionEndTime || 0) / 1000)); // Konvertiere zu Sekunden
      const gebaeudeProduktionen = gebaeudeKeys.map(key => Math.floor(gebaeude[key].produktion * SCALING_FACTOR));
      const gebaeudeMaxArbeiter = gebaeudeKeys.map(key => {
        if (key === 'warehouse') return 0; // Für warehouse auf 0 setzen
        return Math.floor(gebaeude[key].maxArbeiter || 0);
      });
      
      const gebaeudeArbeiter = gebaeudeKeys.map(key => Math.floor(gebaeude[key].arbeiter || 0));

      // Skalierung der Bevölkerungswerte
      const scaledAktuelleBevoelkerung = Math.floor(aktuelleBevoelkerung * POPULATION_SCALING_FACTOR);
      const scaledGenutzteBevoelkerung = Math.floor(genutzteBevoelkerung * POPULATION_SCALING_FACTOR);
      const scaledMaximaleBevoelkerungskapazitaet = Math.floor(maximaleBevoelkerungskapazitaet * POPULATION_SCALING_FACTOR);
      const scaledBevoelkerungswachstumProStunde = Math.floor(bevoelkerungswachstumProStunde * GROWTH_RATE_SCALING_FACTOR);

      // Validierung: Stelle sicher, dass keine null oder undefined Werte vorhanden sind
      const validateArray = (arr, type) => {
        return arr.every(item => {
          if (type === 'uint256') {
            return typeof item === 'number' && Number.isInteger(item) && item >= 0 && !isNaN(item);
          }
          if (type === 'bool') {
            return typeof item === 'boolean';
          }
          return true;
        });
      };
      

      if (
        !validateArray(resourceValues, 'uint256') ||
        !validateArray(gebaeudeLevels, 'uint256') ||
        !validateArray(gebaeudeIsConstructing, 'bool') ||
        !validateArray(gebaeudeConstructionEndTimes, 'uint256') ||
        !validateArray(gebaeudeProduktionen, 'uint256') ||
        !validateArray(gebaeudeMaxArbeiter, 'uint256') ||
        !validateArray(gebaeudeArbeiter, 'uint256') ||
        typeof punkte !== 'number' ||
        typeof resourcenAusgegeben !== 'number' ||
        typeof scaledAktuelleBevoelkerung !== 'number' ||
        typeof scaledGenutzteBevoelkerung !== 'number' ||
        typeof scaledMaximaleBevoelkerungskapazitaet !== 'number' ||
        typeof scaledBevoelkerungswachstumProStunde !== 'number' ||
        typeof bauslots !== 'number' || bauslots < 0 ||
        typeof wirtschaftPunkte !== 'number' ||
        typeof militaerPunkte !== 'number'
      ) {
        throw new Error('Ungültige Daten in den Spielparametern.');
      }

      // Konvertiere lastLogin in Unix-Timestamp in Sekunden
      const lastLoginTimestamp = Math.floor(new Date(lastLogin).getTime() / 1000);

      // Strukturieren der AllPlayerData
      const allData = {
        resources: {
          keys: resourceKeys,
          values: resourceValues,
        },
        gebaeude: {
          keys: gebaeudeKeys,
          levels: gebaeudeLevels,
          isConstructing: gebaeudeIsConstructing,
          constructionEndTimes: gebaeudeConstructionEndTimes,
          produktionen: gebaeudeProduktionen,
          maxArbeiter: gebaeudeMaxArbeiter,
          arbeiter: gebaeudeArbeiter,
        },
        generalStats: {
          punkte: punkte,
          resourcenAusgegeben: resourcenAusgegeben,
          wirtschaftPunkte: wirtschaftPunkte,
          militaerPunkte: militaerPunkte,
          resourcenAusgegebenWirtschaft: resourcenAusgegebenWirtschaft,
          resourcenAusgegebenMilitaer: resourcenAusgegebenMilitaer,
          lastLogin: lastLoginTimestamp,
        },
        populationStats: {
          aktuelleBevoelkerung: scaledAktuelleBevoelkerung,
          genutzteBevoelkerung: scaledGenutzteBevoelkerung,
          maximaleBevoelkerungskapazitaet: scaledMaximaleBevoelkerungskapazitaet,
          bevoelkerungswachstumProStunde: scaledBevoelkerungswachstumProStunde,
        },
        bauslots: bauslots,
      };

      // Berechne den erforderlichen Wert (für die kombinierte Speicherung)
      const totalValueInWei = web3.utils.toWei('0.1', 'ether'); // Passe den Betrag entsprechend an
      console.log('Total Value in Wei:', totalValueInWei); // Debugging

      // Schätze das Gaslimit für die Transaktion
      const gasEstimateCombined = await gamePaymentContract.methods
        .saveAllPlayerData(allData)
        .estimateGas({ from: walletAddress, value: totalValueInWei });
      console.log(`Geschätztes Gaslimit für saveAllPlayerData: ${gasEstimateCombined}`);

      // Hole den aktuellen Gaspreis
      const gasPrice = await web3.eth.getGasPrice();
      console.log(`Aktueller Gaspreis: ${gasPrice}`);

      // Überprüfe den Wallet-Kontostand
      const balance = await web3.eth.getBalance(walletAddress);
      console.log('Wallet Balance:', web3.utils.fromWei(balance, 'ether'), 'Ether');

      // Speichern aller Daten im Smart Contract
      console.log('Speichere alle Daten im GamePayment Smart Contract...');
      await gamePaymentContract.methods.saveAllPlayerData(allData).send({
        from: walletAddress,
        value: totalValueInWei,
        gas: gasEstimateCombined,
        gasPrice: gasPrice, // Gaspreis hinzufügen
      });
      console.log('Alle Daten erfolgreich gespeichert.');

      // Update Leaderboard
      console.log('Aktualisiere Leaderboard...');
      const leaderboardContract = new web3.eth.Contract(LEADERBOARD_ABI, LEADERBOARD_ADDRESS);

      // Schätze das Gaslimit für die Transaktion
      const gasEstimateLeaderboard = await leaderboardContract.methods
        .updatePoints(wirtschaftPunkte, militaerPunkte)
        .estimateGas({ from: walletAddress });

      console.log(`Geschätztes Gaslimit für updatePoints: ${gasEstimateLeaderboard}`);

      // Hole den aktuellen Gaspreis
      const gasPriceLeaderboard = await web3.eth.getGasPrice();
      console.log(`Aktueller Gaspreis: ${gasPriceLeaderboard}`);

      // Aktualisiere die Punkte im Leaderboard
      await leaderboardContract.methods.updatePoints(wirtschaftPunkte, militaerPunkte).send({
        from: walletAddress,
        gas: gasEstimateLeaderboard,
        gasPrice: gasPriceLeaderboard,
      });

      console.log('Leaderboard erfolgreich aktualisiert.');

      return { success: true };
    } else {
      throw new Error('Kein Ethereum-Provider gefunden');
    }
  } catch (error) {
    console.error('Fehler beim Speichern des Spiels:', error);
    if (error.data) {
      console.error('Fehlerdaten:', error.data);
    }
    if (error.stack) {
      console.error('Fehler-Stack:', error.stack);
    }
    alert(`Fehler beim Speichern des Spiels: ${error.message}`);
    return { success: false, message: error.message };
  }
};
