// frontend/src/components/SaveWallet.js
import Web3 from 'web3';
import { CONTRACT_ADDRESS, CONTRACT_ABI } from '../wallet/contractData';

export const saveWalletAddress = async (walletAddress) => {
  try {
    if (window.ethereum) {
      console.log('Ethereum Provider gefunden.');
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const web3 = new Web3(window.ethereum);
      console.log('Web3 Instanz erstellt.');

      const gamePaymentContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
      console.log('Smart Contract instanziiert:', gamePaymentContract.options.address);

      // Überprüfen, ob die Wallet bereits registriert ist
      const isRegistered = await gamePaymentContract.methods.registeredWallets(walletAddress).call();
      if (isRegistered) {
        console.log('Wallet ist bereits registriert.');
        return { success: true, message: 'Wallet ist bereits registriert.' };
      }

      // Gaspreis und Gaslimit schätzen
      const gasPrice = await web3.eth.getGasPrice();
      console.log(`Aktueller Gaspreis: ${gasPrice}`);

      const gasEstimate = await gamePaymentContract.methods.registerWallet().estimateGas({ from: walletAddress });
      console.log(`Geschätztes Gaslimit: ${gasEstimate}`);

      // Transaktion senden, um die Wallet zu registrieren
      console.log('Registriere Wallet im Smart Contract...');
      const tx = await gamePaymentContract.methods.registerWallet().send({
        from: walletAddress,
        gas: gasEstimate,
        gasPrice: gasPrice,
        type: 0, // Für Legacy-Transaktionen
      });
      console.log('Wallet erfolgreich registriert:', tx);

      // Erfolgsnachricht zurückgeben
      return { success: true, message: 'Wallet erfolgreich registriert!' };
    } else {
      throw new Error('Kein Ethereum-Provider gefunden');
    }
  } catch (error) {
    console.error('Fehler in saveWalletAddress:', error);
    if (error.data) {
      console.error('Fehlerdaten:', error.data);
    }
    if (error.stack) {
      console.error('Fehler-Stack:', error.stack);
    }
    // Fehlernachricht zurückgeben
    return { success: false, message: `Fehler beim Registrieren der Wallet: ${error.message}` };
  }
};
