export const CONTRACT_ADDRESS = '0x2274DF7dd73e19AD91134eDbE7333Af565e45Fdd'; //'0xE053e23bCaa9FE68647e2dED7e32643e51535b3e'; testnet
export const CONTRACT_ABI = [{
  "inputs": [
    {
      "internalType": "address payable",
      "name": "_paymentReceiver",
      "type": "address"
    }
  ],
  "stateMutability": "nonpayable",
  "type": "constructor"
},
{
  "anonymous": false,
  "inputs": [
    {
      "indexed": true,
      "internalType": "address",
      "name": "player",
      "type": "address"
    }
  ],
  "name": "PlayerDataLoaded",
  "type": "event"
},
{
  "anonymous": false,
  "inputs": [
    {
      "indexed": true,
      "internalType": "address",
      "name": "player",
      "type": "address"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "punkte",
      "type": "uint256"
    }
  ],
  "name": "PlayerDataSaved",
  "type": "event"
},
{
  "anonymous": false,
  "inputs": [
    {
      "indexed": true,
      "internalType": "address",
      "name": "wallet",
      "type": "address"
    }
  ],
  "name": "WalletRegistered",
  "type": "event"
},
{
  "inputs": [],
  "name": "BAUSLOT_PRICE",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [],
  "name": "owner",
  "outputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [],
  "name": "paymentReceiver",
  "outputs": [
    {
      "internalType": "address payable",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "name": "registeredWallets",
  "outputs": [
    {
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [],
  "name": "buyBauslot",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function",
  "payable": true
},
{
  "inputs": [
    {
      "components": [
        {
          "components": [
            {
              "internalType": "string[]",
              "name": "keys",
              "type": "string[]"
            },
            {
              "internalType": "uint256[]",
              "name": "values",
              "type": "uint256[]"
            }
          ],
          "internalType": "struct GamePayment.ResourceData",
          "name": "resources",
          "type": "tuple"
        },
        {
          "components": [
            {
              "internalType": "string[]",
              "name": "keys",
              "type": "string[]"
            },
            {
              "internalType": "uint256[]",
              "name": "levels",
              "type": "uint256[]"
            },
            {
              "internalType": "bool[]",
              "name": "isConstructing",
              "type": "bool[]"
            },
            {
              "internalType": "uint256[]",
              "name": "constructionEndTimes",
              "type": "uint256[]"
            },
            {
              "internalType": "uint256[]",
              "name": "produktionen",
              "type": "uint256[]"
            },
            {
              "internalType": "uint256[]",
              "name": "maxArbeiter",
              "type": "uint256[]"
            },
            {
              "internalType": "uint256[]",
              "name": "arbeiter",
              "type": "uint256[]"
            }
          ],
          "internalType": "struct GamePayment.GebaeudeData",
          "name": "gebaeude",
          "type": "tuple"
        },
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "punkte",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "resourcenAusgegeben",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "wirtschaftPunkte",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "militaerPunkte",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "resourcenAusgegebenWirtschaft",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "resourcenAusgegebenMilitaer",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "lastLogin",
              "type": "uint256"
            }
          ],
          "internalType": "struct GamePayment.GeneralStats",
          "name": "generalStats",
          "type": "tuple"
        },
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "aktuelleBevoelkerung",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "genutzteBevoelkerung",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "maximaleBevoelkerungskapazitaet",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "bevoelkerungswachstumProStunde",
              "type": "uint256"
            }
          ],
          "internalType": "struct GamePayment.PopulationStats",
          "name": "populationStats",
          "type": "tuple"
        },
        {
          "internalType": "uint256",
          "name": "bauslots",
          "type": "uint256"
        }
      ],
      "internalType": "struct GamePayment.AllPlayerData",
      "name": "allData",
      "type": "tuple"
    }
  ],
  "name": "saveAllPlayerData",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function",
  "payable": true
},
{
  "inputs": [
    {
      "internalType": "string[]",
      "name": "resourceKeys",
      "type": "string[]"
    },
    {
      "internalType": "uint256[]",
      "name": "resourceValues",
      "type": "uint256[]"
    }
  ],
  "name": "savePlayerResources",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function",
  "payable": true
},
{
  "inputs": [
    {
      "internalType": "string[]",
      "name": "gebaeudeKeys",
      "type": "string[]"
    },
    {
      "internalType": "uint256[]",
      "name": "gebaeudeLevels",
      "type": "uint256[]"
    },
    {
      "internalType": "bool[]",
      "name": "gebaeudeIsConstructing",
      "type": "bool[]"
    },
    {
      "internalType": "uint256[]",
      "name": "gebaeudeConstructionEndTimes",
      "type": "uint256[]"
    },
    {
      "internalType": "uint256[]",
      "name": "gebaeudeProduktionen",
      "type": "uint256[]"
    },
    {
      "internalType": "uint256[]",
      "name": "gebaeudeMaxArbeiter",
      "type": "uint256[]"
    },
    {
      "internalType": "uint256[]",
      "name": "gebaeudeArbeiter",
      "type": "uint256[]"
    }
  ],
  "name": "savePlayerBuildings",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function",
  "payable": true
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "punkte",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "resourcenAusgegeben",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "lastLogin",
      "type": "uint256"
    }
  ],
  "name": "savePlayerGeneralStats",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function",
  "payable": true
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "aktuelleBevoelkerung",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "genutzteBevoelkerung",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "maximaleBevoelkerungskapazitaet",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "bevoelkerungswachstumProStunde",
      "type": "uint256"
    }
  ],
  "name": "savePlayerPopulationStats",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function",
  "payable": true
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "playerAddress",
      "type": "address"
    }
  ],
  "name": "loadPlayerResources",
  "outputs": [
    {
      "internalType": "string[]",
      "name": "resourceKeys",
      "type": "string[]"
    },
    {
      "internalType": "uint256[]",
      "name": "resourceValues",
      "type": "uint256[]"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "playerAddress",
      "type": "address"
    }
  ],
  "name": "loadPlayerBuildings",
  "outputs": [
    {
      "internalType": "string[]",
      "name": "gebaeudeKeys",
      "type": "string[]"
    },
    {
      "internalType": "uint256[]",
      "name": "gebaeudeLevels",
      "type": "uint256[]"
    },
    {
      "internalType": "bool[]",
      "name": "gebaeudeIsConstructing",
      "type": "bool[]"
    },
    {
      "internalType": "uint256[]",
      "name": "gebaeudeConstructionEndTimes",
      "type": "uint256[]"
    },
    {
      "internalType": "uint256[]",
      "name": "gebaeudeProduktionen",
      "type": "uint256[]"
    },
    {
      "internalType": "uint256[]",
      "name": "gebaeudeMaxArbeiter",
      "type": "uint256[]"
    },
    {
      "internalType": "uint256[]",
      "name": "gebaeudeArbeiter",
      "type": "uint256[]"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "playerAddress",
      "type": "address"
    }
  ],
  "name": "loadPlayerBauslots",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "bauslots",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "playerAddress",
      "type": "address"
    }
  ],
  "name": "loadPlayerGeneralStats",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "punkte",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "resourcenAusgegeben",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "wirtschaftPunkte",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "militaerPunkte",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "resourcenAusgegebenWirtschaft",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "resourcenAusgegebenMilitaer",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "lastLogin",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "playerAddress",
      "type": "address"
    }
  ],
  "name": "loadPlayerPopulationStats",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "aktuelleBevoelkerung",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "genutzteBevoelkerung",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "maximaleBevoelkerungskapazitaet",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "bevoelkerungswachstumProStunde",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [],
  "name": "withdraw",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "registerWallet",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}];

export const LEADERBOARD_ADDRESS = '0x8724b288528149f528f148166c7B79Ab16366b9A'; 
export const LEADERBOARD_ABI = [{
  "inputs": [],
  "stateMutability": "nonpayable",
  "type": "constructor"
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "name": "isPlayer",
  "outputs": [
    {
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [],
  "name": "owner",
  "outputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "name": "playerAddresses",
  "outputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "name": "players",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "wirtschaftPunkte",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "militaerPunkte",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "_wirtschaftPunkte",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "_militaerPunkte",
      "type": "uint256"
    }
  ],
  "name": "updatePoints",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "getPlayerCount",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "index",
      "type": "uint256"
    }
  ],
  "name": "getPlayerAddress",
  "outputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
}];