// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importiere Übersetzungsdateien
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

// Definiere die Ressourcen
const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  }
};

// Initialisiere i18n
i18n
  .use(LanguageDetector) // Automatische Spracherkennung
  .use(initReactI18next) // Bindet i18n an React
  .init({
    resources,
    fallbackLng: 'en', // Fallback-Sprache, falls die erkannte Sprache nicht verfügbar ist
    interpolation: {
      escapeValue: false // React bereits sicher vor XSS
    }
  });

  

export default i18n;
