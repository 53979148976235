// src/components/Rohstoffe.js

import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './Rohstoffe.css';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Importiere die Bilder
import holzIcon from '../images/HolzRohstoff.webp';
import steinIcon from '../images/SteinRohstoff.webp';
import nahrungIcon from '../images/NahrungRohstoff.webp';
import wasserIcon from '../images/WasserRohstoff.webp';
import kohleIcon from '../images/KohleRohstoff.webp';
import metallIcon from '../images/MetallRohstoff.webp';
import goldIcon from '../images/GoldRohstoff.webp';
import populationIcon from '../images/populationIcon.webp'; 
import bretterIcon from '../images/bretterResourceImage.webp'; 
import hopfenIcon from '../images/hopsRecourceImage.webp';
import furnitureIcon from '../images/furnitureImage.webp';
import beerIcon from '../images/beerRecourceImage.webp';

function Rohstoffe({ resources, currentPopulation, maxPopulation, usedPopulation, gebaeude }) {
  const { t } = useTranslation();

  // Berechnung der freien Bevölkerung
  const freePopulation = resources.population - usedPopulation;

  // Definiere eine Liste von Ressourcen mit ihren Namen und Icons
  const resourceList = [
    { name: t('Rohstoffe.holz'), key: 'holz', icon: holzIcon },
    { name: t('Rohstoffe.stein'), key: 'stein', icon: steinIcon },
    { name: t('Rohstoffe.nahrung'), key: 'nahrung', icon: nahrungIcon },
    { name: t('Rohstoffe.wasser'), key: 'wasser', icon: wasserIcon },
    { name: t('Rohstoffe.kohle'), key: 'kohle', icon: kohleIcon },
    { name: t('Rohstoffe.metall'), key: 'metall', icon: metallIcon },
    { name: t('Rohstoffe.gold'), key: 'gold', icon: goldIcon },
    { name: t('Rohstoffe.bretter'), key: 'bretter', icon: bretterIcon },
    { name: t('Rohstoffe.hopfen'), key: 'hopfen', icon: hopfenIcon },
    { name: t('Rohstoffe.furniture'), key: 'furniture', icon: furnitureIcon },
    { name: t('Rohstoffe.beer'), key: 'beer', icon: beerIcon },
  ];

  // Funktion zur Überprüfung, ob eine Ressource freigeschaltet ist
  const isResourceUnlocked = (resourceKey) => {
    switch(resourceKey) {
      case 'bretter':
        return gebaeude?.bretterbude?.level >=1;
      case 'hopfen':
        return gebaeude?.hopfen?.level >=1;
      case 'furniture':
        return gebaeude?.furniture?.level >=1;
      case 'beer':
        return gebaeude?.brewery?.level >=1;
      default:
        return true; // Basisressourcen sind immer sichtbar
    }
  }

  // Filtere die Ressourcen basierend auf den Freischaltungsbedingungen
  const filteredResourceList = resourceList.filter(resource => {
    if(['bretter', 'hopfen', 'furniture', 'beer'].includes(resource.key)) {
      return isResourceUnlocked(resource.key);
    }
    return true;
  });

  return (
    <div className="rohstoffe-container">
      {filteredResourceList.map(resource => (
        <div
          className="resource"
          key={resource.key}
          data-tooltip-id={`tooltip-${resource.key}`}
          data-tooltip-content={resource.name}
        >
          <img src={resource.icon} alt={resource.name} className="resource-icon" />
          <div className={`resource-value ${resource.key === 'gold' ? 'gold' : ''}`}>
            {resources[resource.key] ? resources[resource.key].toFixed(0) : 0}
          </div>
          <Tooltip
            id={`tooltip-${resource.key}`}
            place="top"
            type="dark"
            effect="solid"
            className="custom-tooltip"
          />
        </div>
      ))}
      {/* Zeige die Bevölkerung separat an */}
      <div
        className="resource"
        key="population"
        data-tooltip-id="tooltip-population"
        data-tooltip-content={t('Rohstoffe.bevoelkerung')}
      >
        <img src={populationIcon} alt={t('Rohstoffe.bevoelkerung')} className="resource-icon" />
        <div className="resource-value population">
          {isNaN(freePopulation) ? 0 : freePopulation.toFixed(0)} / {maxPopulation}
        </div>
        <Tooltip
          id="tooltip-population"
          place="top"
          type="dark"
          effect="solid"
          className="custom-tooltip"
        />
      </div>
    </div>
  );
}

Rohstoffe.propTypes = {
  resources: PropTypes.object.isRequired,
  currentPopulation: PropTypes.number.isRequired,
  maxPopulation: PropTypes.number.isRequired,
  usedPopulation: PropTypes.number.isRequired,
  gebaeude: PropTypes.object.isRequired, 
};

export default Rohstoffe;
