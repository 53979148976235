// src/Game.js
import React, { useState, useEffect, useRef } from 'react';
import Rohstoffe from './components/Rohstoffe';
import SideMenu from './components/SideMenu';
import Gebaeude from './components/Gebaeude';
import Statistiken from './components/Statistiken';
import Rangliste from './components/Rangliste';
import Notification from './components/Notification';
import Arbeiter from './components/Arbeiter'; 
import Shop from './components/Shop';
import { calculateConsumption } from './components/PopulationConsumption';
import { gebaeudeDaten } from './components/gebaeudeDaten';
import { saveGame } from './components/SaveGame';
import { loadGame } from './components/LoadGame';
import { produceResources, consumeResources, calculateBuildingConsumption } from './components/ResourceManager'; 


function Game({ walletAddress }) {
  const [resources, setResources] = useState({
    holz: 500.0,
    stein: 500.0,
    nahrung: 250.0,
    wasser: 250.0,
    kohle: 0,
    metall: 0,
    gold: 0,
    population: 10, 
    bretter: 0,
    hopfen: 0,
    furniture: 0,
    beer: 0,
  });

  const [lagerKapazitaet, setLagerKapazitaet] = useState({
    holz: 500,
    stein: 400,
    nahrung: 750,
    wasser: 500,
    kohle: 400,
    metall: 250,
    gold: 300,
    bretter: 100,
    hopfen: 100,
    furniture: 100,
    beer: 100,
  });

  const [gebaeude, setGebaeude] = useState({});
  const [lastLogin, setLastLogin] = useState(null);
  const [activeView, setActiveView] = useState('buildings');
  const [punkte, setPunkte] = useState(0);
  const [resourcenAusgegeben, setResourcenAusgegeben] = useState(0);
  const [notification, setNotification] = useState(null);

  const handleShowWorkers = () => {
    setActiveView('workers'); 
  };

  const didMount = useRef(false);

  const getGebaeudeInfo = (gebaeudeName) => {
    for (const category of Object.values(gebaeudeDaten)) {
      const gebInfo = category.find((g) => g.name === gebaeudeName);
      if (gebInfo) {
        return gebInfo;
      }
    }
    return null;
  };

  const berechneProduktion = (gebaeudeInfo, level) => {
    if (!gebaeudeInfo || typeof gebaeudeInfo.produktionBasis !== 'number') {
      return 0;
    }
    const faktor = gebaeudeInfo.faktorProduktion;
    const produktion = gebaeudeInfo.produktionBasis * Math.pow(1 + faktor, level - 1);
    return parseFloat(produktion.toFixed(2));
  };
  //Bauslots
  const [bauslots, setBauslots] = useState({
    total: 1,
    available: 1,
  });

  // Funktion zur Berechnung von maxArbeiter
  const berechneMaxArbeiter = (gebaeudeInfo, level) => {
    let maxArbeiter = gebaeudeInfo.maxArbeiter;
    const faktor = gebaeudeInfo.faktorArbeiter || 0;

    maxArbeiter *= Math.pow(1 + faktor, level - 1);
    return Math.ceil(maxArbeiter);
  };

  const berechneMaxPopulationLevel = (houseLevel) => {
    return houseLevel * 10; // Jedes Level erhöht die Kapazität um 10
  };

  // Funktion zur Berechnung der maximalen Bevölkerung basierend auf dem Level der Häuser
  const berechneMaxPopulation = (gebaeudeParam = gebaeude) => {
    const house = gebaeudeParam['house'];
    if (house) {
      return Math.max(berechneMaxPopulationLevel(house.level), 10);
    }
    return 10;
  };
  
  const berechnePopulationGrowthRate = (gebaeudeParam = gebaeude) => {
    const house = gebaeudeParam['house'];
    if (house) {
      return berechnePopulationGrowthRateLevel(house.level);
    }
    return 1;
  };


  const berechnePopulationGrowthRateLevel = (houseLevel) => {
    return 0.05 * Math.pow(1 + 0.7, houseLevel - 1); // Startwert 1, Faktor 0.7 pro Level
  };

  const berechneLagerKapazitaet = (gebaeudeInfo, level) => {
    const kapazitaet = { ...gebaeudeInfo.lagerKapazitaetBasis };
    const faktor = gebaeudeInfo.faktorLagerKapazitaet;
  
    Object.keys(kapazitaet).forEach((ressource) => {
      let wert = kapazitaet[ressource];
      wert *= Math.pow(1 + faktor, level - 1);
      kapazitaet[ressource] = Math.round(wert);
    });
  
    return kapazitaet;
  };
  
//berechnung der Punkte

  const [wirtschaftPunkte, setWirtschaftPunkte] = useState(0);
  const [militaerPunkte, setMilitaerPunkte] = useState(0);
  const [resourcenAusgegebenWirtschaft, setResourcenAusgegebenWirtschaft] = useState(0);
  const [resourcenAusgegebenMilitaer, setResourcenAusgegebenMilitaer] = useState(0);

  const addRessourcenAusgabe = (betrag, typ) => {
    // Update total resources spent
    setResourcenAusgegeben((prev) => {
      const neueSumme = prev + betrag;
      if (neueSumme >= 1000) {
        const neuePunkte = Math.floor(neueSumme / 1000);
        setPunkte((prevPunkte) => prevPunkte + neuePunkte);
        return neueSumme % 1000;
      }
      return neueSumme;
    });
  
    // Update points based on type
    if (typ === 'economy' || typ === 'civil') {
      setResourcenAusgegebenWirtschaft((prev) => {
        const neueSumme = prev + betrag;
        if (neueSumme >= 1000) {
          const neuePunkte = Math.floor(neueSumme / 1000);
          setWirtschaftPunkte((prevPunkte) => prevPunkte + neuePunkte);
          return neueSumme % 1000;
        }
        return neueSumme;
      });
    } else if (typ === 'military') {
      setResourcenAusgegebenMilitaer((prev) => {
        const neueSumme = prev + betrag;
        if (neueSumme >= 1000) {
          const neuePunkte = Math.floor(neueSumme / 1000);
          setMilitaerPunkte((prevPunkte) => prevPunkte + neuePunkte);
          return neueSumme % 1000;
        }
        return neueSumme;
      });
    }
  };

  const initializeNewGame = async () => {
    const initialGebaeude = {};

    Object.keys(gebaeudeDaten).forEach((category) => {
      gebaeudeDaten[category].forEach((geb) => {
        let initialLevel = 0; // Standardmäßig auf 0 setzen
        if (['lumberjack', 'farm', 'house'].includes(geb.name)) {
          initialLevel = 1;
        }

        let initialProduktion = initialLevel > 0 ? berechneProduktion(geb, initialLevel) : 0;
        let initialMaxArbeiter = geb.maxArbeiterBasis ? berechneMaxArbeiter(geb, initialLevel) : 0;

        initialGebaeude[geb.name] = {
          level: initialLevel,
          produktion: initialProduktion,
          isConstructing: false,
          constructionEndTime: null,
          arbeiter: 0,
          maxArbeiter: initialMaxArbeiter,
        };
      });
    });

    setGebaeude(initialGebaeude);
    
    // Initialisiere Ressourcen
    const initialResources = {
      holz: 500,
      stein: 500,
      nahrung: 500,
      wasser: 500,
      kohle: 0,
      metall: 0,
      gold: 0,
      population: 10, // Startbevölkerung
      bretter: 0,
      hopfen: 0,
      furniture: 0,
      beer: 0,
    };
    setResources(initialResources);
  
    // Setze Punkte und ausgegebene Ressourcen
    setPunkte(0);
    setResourcenAusgegeben(0);
  
    // Setze das aktuelle Datum als lastLogin
    const currentTimestamp = new Date().toISOString();
    setLastLogin(currentTimestamp);
  };

  // Speichern der ganzen Spieldaten
  useEffect(() => {
    if (didMount.current) return; // Verhindert erneute Ausführung
    didMount.current = true; // Markiert, dass die Funktion ausgeführt wurde

    const fetchData = async () => {
      //console.log('Lade Spiel für Wallet-Adresse:', walletAddress);
      const savedData = await loadGame(walletAddress);
      //console.log('Geladene Daten:', savedData);

      if (savedData && savedData.success) {
        const isGebaeudeEmpty = !savedData.data.gebaeude || Object.keys(savedData.data.gebaeude).length === 0;
        const isResourcesEmpty = !savedData.data.resources || Object.keys(savedData.data.resources).length === 0;

        //console.log('isGebaeudeEmpty:', isGebaeudeEmpty);
        //console.log('isResourcesEmpty:', isResourcesEmpty);

        if (isGebaeudeEmpty && isResourcesEmpty) {
          //console.log('Leere Daten für gebaeude und resources erkannt. Initialisiere neues Spiel.');
          await initializeNewGame();
          return;
        }

        // Optional: Behandle teilweise fehlende Daten anders
        if (isGebaeudeEmpty || isResourcesEmpty) {
          //console.warn('Teilweise leere Daten erkannt.');
          // Initialisiere nur fehlende Teile
          if (isGebaeudeEmpty) {
            //console.log('Initialisiere Gebäude.');
            const initialGebaeude = {};
            Object.keys(gebaeudeDaten).forEach((category) => {
              gebaeudeDaten[category].forEach((geb) => {
                initialGebaeude[geb.name] = {
                  level: 1,
                  produktion: berechneProduktion(geb, 1),
                  isConstructing: false,
                  constructionEndTime: null,
                  arbeiter: 0, // Arbeiter initialisieren
                };
              });
            });
            setGebaeude(initialGebaeude);
          }
          if (isResourcesEmpty) {
            //console.log('Initialisiere Ressourcen.');
            const initialResources = {
              holz: 100,
              stein: 100,
              nahrung: 50,
              wasser: 50,
              kohle: 0,
              metall: 0,
              gold: 0,
              population: 20, // Startbevölkerung
              bretter: 0, // Initialisiere neue Ressourcen
              hopfen: 0,
              furniture: 0,
              beer: 0,
            };
            setResources(initialResources);
          }
          return;
        }

        // Setze die Ressourcen
        const numericResources = savedData.data.resources;
        const initialPopulation = savedData.data.aktuelleBevoelkerung || 10;
        setResources({
          ...numericResources,
          population: initialPopulation, // Bevölkerungsstand setzen
        });

        setResourcenAusgegeben(savedData.data.resourcenAusgegeben || 0);

        setBauslots({
          total: savedData.data.bauslots.total,
          available: savedData.data.bauslots.available,
        });

        // Berechne die Lagerkapazität basierend auf dem Lager-Gebäude
        const lagerGebaeude = savedData.data.gebaeude['warehouse'];
        let berechneteLagerKapazitaet = {
          holz: 500,
          stein: 400,
          nahrung: 750,
          wasser: 500,
          kohle: 400,
          metall: 250,
          gold: 300,
          bretter: 100,
          hopfen: 100,
          furniture: 100,
          beer: 100,
        };

        if (lagerGebaeude && lagerGebaeude.level > 0) {
          const lagerInfo = getGebaeudeInfo('warehouse');
          if (lagerInfo) {
            berechneteLagerKapazitaet = berechneLagerKapazitaet(lagerInfo, lagerGebaeude.level);
          }
        }

        setLagerKapazitaet(berechneteLagerKapazitaet);

        // Berechne die Offline-Zeit
        const lastLoginTime = new Date(savedData.data.lastLogin).getTime(); // Korrigiert, falls lastLogin ein ISO-String ist
        const currentTime = Date.now();
        const timeDifferenceInSeconds = Math.floor((currentTime - lastLoginTime) / 1000); // Zeitdifferenz in Sekunden

        //console.log(`Offline-Zeit: ${timeDifferenceInSeconds} Sekunden`);

        // Berechne die produzierte Menge für jede Ressource
        const offlineProduction = {
          holz: 0,
          stein: 0,
          nahrung: 0,
          wasser: 0,
          kohle: 0,
          metall: 0,
          gold: 0,
          population: 0, // Bevölkerungswachstum
          bretter: 0, // Initialisiere neue Ressourcen
          hopfen: 0,
          furniture: 0,
          beer: 0,
        };

        // Adjusted Gebäudedaten für Offline-Berechnung
        const adjustedGebaeude = { ...savedData.data.gebaeude };

        // Verarbeite Bauzeiten und Level-Up
        Object.keys(adjustedGebaeude).forEach((gebaeudeName) => {
          const geb = adjustedGebaeude[gebaeudeName];
          geb.level = Math.max(geb.level, 1);
          if (geb.isConstructing && geb.constructionEndTime) {
            const remainingTime = geb.constructionEndTime - timeDifferenceInSeconds;
            if (remainingTime <= 0) {
              // Bau abgeschlossen
              geb.level += 1;
              geb.isConstructing = false;
              geb.constructionEndTime = null;
              // Aktualisiere die Produktion
              const gebaeudeInfo = getGebaeudeInfo(gebaeudeName);
              if (gebaeudeInfo) {
                geb.produktion = berechneProduktion(gebaeudeInfo, geb.level);
              }
            } else {
              // Bau noch nicht abgeschlossen, setze die neue Endzeit basierend auf aktueller Zeit
              geb.constructionEndTime = timeDifferenceInSeconds + remainingTime;
            }
          }
        });

        // Berechne die maximale Bevölkerung und Wachstumsrate basierend auf adjustedGebaeude
        const maxPopulation = berechneMaxPopulation(adjustedGebaeude);
        const populationGrowthRate = berechnePopulationGrowthRate(adjustedGebaeude);

        // Berechne die Offline-Produktion basierend auf adjustedGebaeude
        Object.keys(adjustedGebaeude).forEach((gebaeudeName) => {
          const geb = adjustedGebaeude[gebaeudeName];
          if (!geb.isConstructing && geb.produktion > 0) {
            switch (gebaeudeName) {
              case 'lumberjack':
                offlineProduction.holz += geb.produktion * timeDifferenceInSeconds;
                break;
              case 'stonemason':
                offlineProduction.stein += geb.produktion * timeDifferenceInSeconds;
                break;
              case 'farm':
                offlineProduction.nahrung += geb.produktion * timeDifferenceInSeconds;
                break;
              case 'drawingWell':
                offlineProduction.wasser += geb.produktion * timeDifferenceInSeconds;
                break;
              case 'coalMine':
                offlineProduction.kohle += geb.produktion * timeDifferenceInSeconds;
                break;
              case 'erzmine':
                offlineProduction.metall += geb.produktion * timeDifferenceInSeconds;
                break;
              // Keine Bevölkerung hier, da wir sie separat behandeln
              default:
                break;
            }
          }
        });

        // Bevölkerungswachstum basierend auf der Offline-Zeit
        offlineProduction.population += populationGrowthRate * timeDifferenceInSeconds;

        //console.log('Offline-Produktion:', offlineProduction);

        // Addiere die offline produzierten Ressourcen zu den aktuellen Ressourcen, ohne die Lagerkapazität zu überschreiten
        const updatedResources = { ...numericResources };

        for (const res in offlineProduction) {
          if (offlineProduction.hasOwnProperty(res)) {
            if (res === 'population') {
              updatedResources.population += offlineProduction[res];
              // Begrenze die Bevölkerung auf maxPopulation
              if (updatedResources.population > maxPopulation) {
                updatedResources.population = maxPopulation;
              }
            } else {
              updatedResources[res] += offlineProduction[res];
              if (updatedResources[res] > berechneteLagerKapazitaet[res]) {
                updatedResources[res] = berechneteLagerKapazitaet[res];
              }
            }
          }
        }

        //console.log('Aktualisierte Ressourcen nach Offline-Produktion:', updatedResources);

        // Setze die aktualisierten Ressourcen und Gebäude
        setResources(updatedResources);
        setGebaeude(adjustedGebaeude);

        // Setze die Punkte
        setPunkte(savedData.data.punkte || 0);
        setResourcenAusgegeben(savedData.data.resourcenAusgegeben || 0);
        setWirtschaftPunkte(savedData.data.wirtschaftPunkte || 0); 
        setMilitaerPunkte(savedData.data.militaerPunkte || 0);    
        setResourcenAusgegebenWirtschaft(savedData.data.resourcenAusgegebenWirtschaft || 0); 
        setResourcenAusgegebenMilitaer(savedData.data.resourcenAusgegebenMilitaer || 0);    

        // Setze das aktuelle Datum als lastLogin
        const newLastLogin = new Date().toISOString();
        setLastLogin(newLastLogin);
        //console.log('Setze Punkte:', savedData.data.punkte);
        //console.log('Setze Resourcen Ausgegeben:', savedData.data.resourcenAusgegeben);
        //console.log('Setze Last Login:', newLastLogin);

        //console.log('Spiel geladen und Offline-Produktion angewendet:', savedData);
      } else {
        //console.log('Keine gespeicherten Daten gefunden. Initialisiere neues Spiel.');
        await initializeNewGame();
      }
    };

    fetchData();
  }, [walletAddress]);

  useEffect(() => {
    const berechneKapazitaet = () => {
      const houseLevel = gebaeude['house']?.level || 0;
      const maxPopulation = berechneMaxPopulationLevel(houseLevel);
      const currentMaxPopulation = maxPopulation;

      setLagerKapazitaet((prev) => ({
        ...prev,
        population: currentMaxPopulation, // Spezifische Lagerkapazität für Bevölkerung
      }));
    };

    berechneKapazitaet();
  }, [gebaeude]);

  // Funktion zum Anzeigen einer Benachrichtigung
  const showNotification = (message, type = 'success') => {
    setNotification({ message, type });
  };

  // Funktion zum Schließen der Benachrichtigung
  const closeNotification = () => {
    setNotification(null);
  };

  // Funktion zum manuellen Speichern des Spiels
  const handleSave = async () => {
    try {
      const aktuelleBevoelkerung = resources.population;
      const genutzteBevoelkerung = berechneUsedPopulation(gebaeude);
      const maximaleBevoelkerungskapazitaet = berechneMaxPopulation();
      const bevoelkerungswachstumProStunde = berechnePopulationGrowthRate();

      console.log('Speichere Spiel mit den folgenden Parametern:', {
        aktuelleBevoelkerung,
        genutzteBevoelkerung,
        maximaleBevoelkerungskapazitaet,
        bevoelkerungswachstumProStunde,
      });

      const result = await saveGame(
        walletAddress,
        { ...resources, population: aktuelleBevoelkerung }, // Bevölkerungsstand speichern
        gebaeude,
        punkte,
        resourcenAusgegeben,
        lastLogin,
        aktuelleBevoelkerung,
        genutzteBevoelkerung,
        maximaleBevoelkerungskapazitaet,
        bevoelkerungswachstumProStunde,
        bauslots.total,
        wirtschaftPunkte, 
        militaerPunkte,   
        resourcenAusgegebenWirtschaft, 
        resourcenAusgegebenMilitaer    
      );

      if (result.success) {
        //console.log('Spiel erfolgreich gespeichert!');
        showNotification('Spiel erfolgreich gespeichert!', 'success');
      } else {
        //console.error('Fehler beim Speichern des Spiels:', result.message);
        showNotification(`Fehler beim Speichern des Spiels: ${result.message}`, 'error');
      }
    } catch (error) {
      //console.error('Unbekannter Fehler beim Speichern:', error);
      showNotification(`Unbekannter Fehler beim Speichern des Spiels: ${error.message}`, 'error');
    }
  };

  //Berechnung der insgesmt zugewiesenen Bevölkerung
  const berechneUsedPopulation = (gebaeude) => {
    let usedPopulation = 0;
    Object.keys(gebaeude).forEach((gebaeudeName) => {
      const geb = gebaeude[gebaeudeName];
      const gebInfo = getGebaeudeInfo(gebaeudeName);
      if (geb.level > 0 && gebInfo && geb.arbeiter) { 
        const workerPopulationCost = gebInfo.workerPopulationCost || 1;
        usedPopulation += geb.arbeiter * workerPopulationCost;
      }
    });
    return usedPopulation;
  };
  

  // Produktions- und Verbrauchslogik
  useEffect(() => {
    const interval = setInterval(() => {
      setResources((prevResources) => {
        // Produktion hinzufügen, ohne das Maximum zu überschreiten
        const resourcesAfterProduction = produceResources(gebaeude, lagerKapazitaet, prevResources);
  
        // Bevölkerungswachstum
        let updatedResources = { ...resourcesAfterProduction };
        const populationGrowthRate = berechnePopulationGrowthRate();
        const maxPopulation = berechneMaxPopulation();
  
        //const usedPopulation = berechneUsedPopulation(gebaeude); //eventuell löschen mal gucken
        const freePopulation = berechneMaxPopulation() - berechneUsedPopulation(gebaeude);
  
        if (freePopulation > 0) {
          updatedResources.population += Math.min(populationGrowthRate, freePopulation);
        }
  
        if (updatedResources.population > maxPopulation) {
          updatedResources.population = maxPopulation;
        }
        //doppelter verbrauch? -> Statistiken.js/PoplationCpnsumption.js <-------------------------------------
        // Verbrauch von Nahrung und Wasser durch Bevölkerung
        const consumptionByPopulation = calculateConsumption(updatedResources.population);
        //console.log('Verbrauch durch Bevölkerung:', consumptionByPopulation);
  
        // Verbrauch durch Gebäude
        const consumptionByBuildings = calculateBuildingConsumption(gebaeude);
        //console.log('Verbrauch durch Gebäude:', consumptionByBuildings);
  
        // Gesamter Verbrauch kombinieren
        const totalConsumption = { ...consumptionByPopulation };
        Object.keys(consumptionByBuildings).forEach((ressource) => {
          totalConsumption[ressource] = (totalConsumption[ressource] || 0) + consumptionByBuildings[ressource];
        });
  
        // Subtrahiere den gesamten Verbrauch von den Ressourcen
        updatedResources = consumeResources(updatedResources, totalConsumption);
  
        return updatedResources;
      });
    }, 1000); // Update jede Sekunde
  
    return () => clearInterval(interval);
  }, [gebaeude, lagerKapazitaet]);

  const getTotalProduction = () => {
    const totalProduction = {
      holz: 0,
      stein: 0,
      nahrung: 0,
      wasser: 0,
      kohle: 0,
      metall: 0,
      gold: 0,
      bretter: 0,
      hopfen: 0,
      furniture: 0,
      beer: 0,
      // Keine Produktion für Bevölkerung hier, da sie separat wächst
    };

    Object.keys(gebaeude).forEach((gebaeudeName) => {
      const geb = gebaeude[gebaeudeName];
      if (!geb.isConstructing && geb.produktion && geb.arbeiter > 0) {
        const arbeiterAnteil = geb.arbeiter / geb.maxArbeiter;
        const aktuelleProduktion = geb.produktion * arbeiterAnteil;
  
        switch (gebaeudeName) {
          case 'lumberjack':
            totalProduction.holz += aktuelleProduktion;
            break;
          case 'stonemason':
            totalProduction.stein += aktuelleProduktion;
            break;
          case 'farm':
            totalProduction.nahrung += aktuelleProduktion;
            break;
          case 'drawingWell':
            totalProduction.wasser += aktuelleProduktion;
            break;
          case 'coalMine':
            totalProduction.kohle += aktuelleProduktion;
            break;
          case 'erzmine':
            totalProduction.metall += aktuelleProduktion;
            break;
          case 'bretterbude':
            totalProduction.bretter += aktuelleProduktion;
            break;
          case 'hopfenfarm': // Angepasst von 'hopfenfarm' zu 'hopfenfarm'
            totalProduction.hopfen += aktuelleProduktion;
            break;
          case 'furniture':
            totalProduction.furniture += aktuelleProduktion;
            break;
          case 'brewery': // Angepasst von 'brewery' zu 'brewery'
            totalProduction.beer += aktuelleProduktion;
            break;
          default:
            break;
        }
      }
    });
  
    return totalProduction;
  };

  const handleShowBuildings = () => {
    setActiveView('buildings');
  };

  const handleShowStatistics = () => {
    setActiveView('statistics');
  };


  const handleShowRangliste = () => {
    setActiveView('rangliste');
  };

  const handleShowShop = () => {
    setActiveView('shop');
  };

  return (
    <>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={closeNotification}
        />
      )}
      <div style={{ display: 'flex' }}>
        <SideMenu
          walletAddress={walletAddress}
          onSave={handleSave}
          onShowBuildings={handleShowBuildings}
          onShowWorkers={handleShowWorkers}
          onShowStatistics={handleShowStatistics}
          onShowRangliste={handleShowRangliste}
          onShowShop={handleShowShop}
          activeView={activeView}
        />
        <div style={{ flex: 1 }}>
          <Rohstoffe
            resources={resources}
            currentPopulation={resources.population}
            maxPopulation={berechneMaxPopulation()}
            usedPopulation={berechneUsedPopulation(gebaeude)}
            gebaeude={gebaeude} 
          />
          
          {activeView === 'buildings' && (
            <Gebaeude
              resources={resources}
              setResources={setResources}
              gebaeude={gebaeude}
              setGebaeude={setGebaeude}
              lagerKapazitaet={lagerKapazitaet}
              setLagerKapazitaet={setLagerKapazitaet}
              addRessourcenAusgabe={addRessourcenAusgabe}
              showNotification={showNotification}
              maxPopulation={berechneMaxPopulation()}
              usedPopulation={berechneUsedPopulation(gebaeude)}
              bauslots={bauslots}
              setBauslots={setBauslots}
            />
          )}
          
          {activeView === 'statistics' && (
            <Statistiken
              resources={resources}
              lagerKapazitaet={lagerKapazitaet}
              totalProduction={getTotalProduction()}
              bauslots={bauslots}
              wirtschaftPunkte={wirtschaftPunkte} // Pass economic points
              militaerPunkte={militaerPunkte}
              maxPopulation={berechneMaxPopulation()}
              populationGrowthRate={berechnePopulationGrowthRate()}
              usedPopulation={berechneUsedPopulation(gebaeude)}
              gebaeude={gebaeude} // Hinzufügen der Gebäudeinformationen
            />
          )}

          {activeView === 'rangliste' && (
            <Rangliste />
          )}
          {activeView === 'workers' && (
            <Arbeiter
              gebaeude={gebaeude}
              setGebaeude={setGebaeude}
              resources={resources} // Stellen Sie sicher, dass diese Prop übergeben wird
              showNotification={showNotification}
            />
          )}
          {activeView === 'shop' && (
            <Shop
              bauslots={bauslots}
              setBauslots={setBauslots}
              walletAddress={walletAddress}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Game;
