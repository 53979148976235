// src/components/Rangliste.js

import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useTranslation } from 'react-i18next'; // Importiere den Übersetzungshook
import { LEADERBOARD_ADDRESS, LEADERBOARD_ABI } from '../wallet/contractData';
import './Rangliste.css';

function Rangliste() {
    const { t } = useTranslation(); // Initialisiere den Übersetzungshook
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(false);

    async function fetchPlayers() {
        if (window.ethereum) {
            try {
                setLoading(true);
                const provider = new ethers.BrowserProvider(window.ethereum);
                await provider.send("eth_requestAccounts", []);

                const signer = await provider.getSigner();
                const leaderboardContract = new ethers.Contract(LEADERBOARD_ADDRESS, LEADERBOARD_ABI, signer);

                const totalPlayers = await leaderboardContract.getPlayerCount();
                const playersData = [];

                for (let i = 0n; i < totalPlayers; i++) {
                    // Spieleradresse nach Index abrufen
                    const playerAddress = await leaderboardContract.getPlayerAddress(i);
                    // Spielerpunkte abrufen
                    const player = await leaderboardContract.players(playerAddress);
                    playersData.push({
                        address: playerAddress,
                        wirtschaftPunkte: player.wirtschaftPunkte.toString(),
                        militaerPunkte: player.militaerPunkte.toString(),
                    });
                }

                // Sortieren nach Wirtschaftspunkten (kann nach Bedarf angepasst werden)
                playersData.sort((a, b) => b.wirtschaftPunkte - a.wirtschaftPunkte);

                setPlayers(playersData);
                setLoading(false);
            } catch (error) {
                console.error('Fehler beim Abrufen der Spieler:', error);
                setLoading(false);
            }
        } else {
            console.error('Ethereum Wallet nicht gefunden');
        }
    }

    useEffect(() => {
        fetchPlayers();
    }, []);

    return (
        <div className="rangliste-container">
            <h2>{t('Rangliste.leaderboard_title')}</h2>
            <button onClick={fetchPlayers} disabled={loading}>
                {loading ? t('Rangliste.loading') : t('Rangliste.update')}
            </button>
            {players.length === 0 && !loading ? (
                <p>{t('Rangliste.noPlayers')}</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>{t('Rangliste.player')}</th>
                            <th>{t('Rangliste.economicPoints')}</th>
                            <th>{t('Rangliste.militaryPoints')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {players.map((player, index) => (
                            <tr key={index}>
                                <td>{player.address.length > 5 ? '****' + player.address.slice(-5) : player.address}</td>
                                <td>{player.wirtschaftPunkte}</td>
                                <td>{player.militaerPunkte}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default Rangliste;
