// src/components/SideMenu.js
import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './SideMenu.css';
import { useTranslation } from 'react-i18next';

// Importiere die Bilder
import saveIcon from '../images/SpeichernIcon.webp';
import buildingsIcon from '../images/GebaeudeIcon.webp';
import statisticsIcon from '../images/StatistikenIcon.webp';
import adminIcon from '../images/populationIcon.webp';
import ranglisteIcon from '../images/RanglisteIcon.webp';
import shopIcon from '../images/shopImage.webp'; 

// Importiere den Sprachumschalter
import LanguageSwitcher from './LanguageSwitcher';

function SideMenu({ 
  walletAddress, 
  onSave, 
  onShowBuildings, 
  onShowStatistics, 
  onShowAdmin, 
  onShowRangliste, 
  onShowWorkers, 
  onShowShop, // Neue Prop hinzufügen
  activeView, 
  punkte 
}) {
  const { t } = useTranslation();

  const menuItems = [
    { name: t('SideMenu.speichern'), key: 'save', icon: saveIcon, action: onSave },
    { name: t('SideMenu.statistiken'), key: 'statistics', icon: statisticsIcon, action: onShowStatistics },
    { name: t('SideMenu.gebaeude'), key: 'buildings', icon: buildingsIcon, action: onShowBuildings },
    { name: t('SideMenu.arbeiter'), key: 'workers', icon: adminIcon, action: onShowWorkers },
    { name: t('SideMenu.rangliste'), key: 'rangliste', icon: ranglisteIcon, action: onShowRangliste },
    { name: t('SideMenu.shop'), key: 'shop', icon: shopIcon, action: onShowShop }, // Neuer Menüeintrag für den Shop
  ];

  return (
    <div className="side-menu">
      <LanguageSwitcher />
      {menuItems.map(item => (
        <div key={item.key}>
          <button 
            onClick={item.action} 
            className={`side-menu-button ${activeView === item.key ? 'active' : ''}`}
            data-tooltip-id={`tooltip-${item.key}`}
            data-tooltip-content={item.name}
          >
            <img src={item.icon} alt={item.name} className="menu-icon" />
          </button>
          <Tooltip
            id={`tooltip-${item.key}`}
            place="right"
            type="dark"
            effect="solid"
            className="custom-tooltip"
          />
        </div>
      ))}
    </div>
  );
}

export default SideMenu;
