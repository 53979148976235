// src/components/Gebaeude.js

import React, { useState, useEffect } from 'react';
import { gebaeudeDaten } from './gebaeudeDaten';
import './Gebaeude.css'; 
import { useTranslation } from 'react-i18next';
import Notification from './Notification';
import PropTypes from 'prop-types';

function Gebaeude({ 
  resources, 
  setResources, 
  gebaeude, 
  setGebaeude, 
  lagerKapazitaet, 
  addRessourcenAusgabe, 
  showNotification,
  maxPopulation,      
  usedPopulation,
  setLagerKapazitaet,
  bauslots,          // Hinzugefügt
  setBauslots,       // Hinzugefügt
}) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('economy'); 
  const [notifications, setNotifications] = useState([]); 

  const freePopulation = maxPopulation - usedPopulation;

  // Entfernt: isAnyBuildingConstructing, da wir jetzt mehrere Gebäude bauen können

  // Funktion zur Abrufung der Gebäudedaten
  function getGebaeudeInfo(gebaeudeName) {
    for (const category of Object.values(gebaeudeDaten)) {
      const gebInfo = category.find((g) => g.name === gebaeudeName);
      if (gebInfo) {
        return gebInfo;
      }
    }
    return null;
  }

  // Funktion zur Überprüfung, ob ein Gebäude freigeschaltet ist
  const isBuildingUnlocked = (gebaeudeInfo) => {
    if (!gebaeudeInfo.unlockLevel || !gebaeudeInfo.requiredBuilding) {
      return true; // Gebäude ist immer sichtbar
    }
    const requiredGebaeude = gebaeude[gebaeudeInfo.requiredBuilding];
    if (!requiredGebaeude || typeof requiredGebaeude.level !== 'number') {
      return false; // Voraussetzung ist nicht erfüllt oder nicht vorhanden
    }
    return requiredGebaeude.level >= gebaeudeInfo.unlockLevel;
  };

  // Funktion zum Hinzufügen von Benachrichtigungen
  const addNotification = (message, type) => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
  };

  // Funktion zum Upgrade von Gebäuden
  const handleUpgrade = (gebaeudeName) => {
    const gebaeudeInfo = getGebaeudeInfo(gebaeudeName);
    const geb = gebaeude[gebaeudeName] || {};
  
    const currentLevel = typeof geb.level === 'number' && !isNaN(geb.level) ? geb.level : 0;
  
    // Überprüfe, ob das Gebäude gerade gebaut wird
    if (geb.isConstructing) {
      addNotification(t('alert.buildingAlreadyUnderConstruction'), 'error');
      return;
    }
  
    // Prüfe, ob genügend Bauslots verfügbar sind
    if (bauslots.available <= 0) {
      addNotification(t('alert.noAvailableBuildingSlots'), 'error');
      return;
    }
  
    const { kosten, populationCost } = berechneKosten(gebaeudeInfo, currentLevel + 1);
  
    // Überprüfe die Ressourcen und die Bevölkerung
    const genugRessourcen = Object.keys(kosten).every(
      (ressource) => resources[ressource] >= kosten[ressource]
    ) && freePopulation >= populationCost;
  
    if (!genugRessourcen) {
      if (freePopulation < populationCost) {
        addNotification(t('alert.notEnoughPopulationForBuilding'), 'error');
      } else {
        addNotification(t('alert.notEnoughResources'), 'error');
      }
      return;
    }
  
    // Ziehe die Ressourcen ab
    const neueRessourcen = { ...resources };
    Object.keys(kosten).forEach((ressource) => {
      neueRessourcen[ressource] -= kosten[ressource];
    });
    neueRessourcen.population -= populationCost; // Bevölkerung abziehen
    setResources(neueRessourcen);
  
    // Berechne die Gesamtkosten
    const totalCost = Object.values(kosten).reduce((sum, cost) => sum + cost, 0);
  
    // Rufe addRessourcenAusgabe auf
    addRessourcenAusgabe(totalCost, gebaeudeInfo.typ);
  
    const bauzeit = berechneBauzeit(gebaeudeInfo, currentLevel + 1);
    const constructionEndTime = Date.now() + bauzeit * 1000;
  
    // Initialisiere das Gebäude, falls es noch nicht existiert
    setGebaeude(prevGebaeude => {
      const geb = prevGebaeude[gebaeudeName] || {
        level: 0,
        produktion: 0,
        arbeiter: 0,
        maxArbeiter: 0,
      };
      return {
        ...prevGebaeude,
        [gebaeudeName]: {
          ...geb,
          isConstructing: true,
          constructionEndTime,
        },
      };
    });
  
    // Reduziere die verfügbaren Bauslots
    setBauslots(prevBauslots => ({
      ...prevBauslots,
      available: prevBauslots.available - 1,
    }));
  
    addNotification(t('notification.upgradeStarted', { gebaeude: t(`Gebaeude.${gebaeudeName}`), level: currentLevel + 1 }), 'success');
  };
  

  // Formatierungsfunktion für Zeit
  const formatTime = (seconds) => {
    if (seconds < 60) {
      return `${seconds}s`;
    } else if (seconds < 3600) {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${mins}m ${secs}s`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      return `${hours}h ${mins}m`;
    } else {
      const days = Math.floor(seconds / 86400);
      const hours = Math.floor((seconds % 86400) / 3600);
      return `${days}d ${hours}h`;
    }
  };

  // Funktion zur Erhöhung der MaxArbeiter beim Level-Up
  const berechneMaxArbeiter = (gebaeudeInfo, level) => {
    let maxArbeiter = gebaeudeInfo.maxArbeiter;
    const faktor = gebaeudeInfo.faktorArbeiter || 0;

    maxArbeiter *= Math.pow(1 + faktor, level - 1);
    return Math.ceil(maxArbeiter);
  };

  // Funktion zur Berechnung der Lagerkapazität
  const berechneLagerKapazitaet = (gebaeudeInfo, level) => {
    const kapazitaet = { ...gebaeudeInfo.lagerKapazitaetBasis };
    const faktor = gebaeudeInfo.faktorLagerKapazitaet;

    Object.keys(kapazitaet).forEach((ressource) => {
      let wert = kapazitaet[ressource];
      wert *= Math.pow(1 + faktor, level - 1);
      kapazitaet[ressource] = Math.round(wert);
    });

    return kapazitaet;
  };

  // Funktion zur Berechnung der Kosten eines Upgrades
  const berechneKosten = (gebaeudeInfo, level) => {
    const kosten = {};
    let populationCost = 0;
    const faktor = gebaeudeInfo.faktorKosten || 0;
  
    const multiplicator = 1 + faktor;
  
    if (typeof gebaeudeInfo.buildingPopulationCost === 'number') {
      populationCost = Math.round(gebaeudeInfo.buildingPopulationCost * Math.pow(multiplicator, level - 1));
    }
  
    Object.keys(gebaeudeInfo.kostenBasis).forEach((ressource) => {
      let grundkosten = gebaeudeInfo.kostenBasis[ressource];
      grundkosten *= Math.pow(multiplicator, level - 1);
      kosten[ressource] = Math.round(grundkosten);
    });
  
    // Zusätzliche Kosten hinzufügen, falls erforderlich
    if (level >= 5 && level < 15) {
      kosten['kohle'] = (kosten['kohle'] || 0) + 10 * level;
      kosten['bretter'] = (kosten['bretter'] || 0) + 5 * level;
    } else if (level >= 15 && level < 25) {
      kosten['metall'] = (kosten['metall'] || 0) + 20 * level;
    } else if (level >= 25) {
      kosten['gold'] = (kosten['gold'] || 0) + 30 * level;
    }
  
    return { kosten, populationCost };
  };
  
  

  const berechneProduktion = (gebaeudeInfo, level) => {
    if (gebaeudeInfo.name.toLowerCase() === 'house') {
      return berechnePopulationGrowthRate(level);
    } else {
      let produktion = gebaeudeInfo.produktionBasis || 0; 
      const faktor = gebaeudeInfo.faktorProduktion || 0; 
  
      produktion *= Math.pow(1 + faktor, level - 1);
      return parseFloat(produktion.toFixed(2));
    }
  };
  

  // Funktion zur Berechnung der Bevölkerungswachstumsrate
  const berechnePopulationGrowthRate = (level) => {
    return 1 * Math.pow(1 + 0.7, level - 1);
  };

  // Funktion zur Berechnung der Bauzeit
  const berechneBauzeit = (gebaeudeInfo, level) => {
    const faktor = gebaeudeInfo.faktorBauzeit;
    const bauzeit = gebaeudeInfo.bauzeitBasis * Math.pow(1 + faktor, level - 1);
    return Math.ceil(bauzeit);
  };

  const tabs = Object.keys(gebaeudeDaten); // ['economy', 'civil', 'military']

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      let updated = false;
      const updatedGebaeude = { ...gebaeude };
      let bauslotsFreigeben = false;
  
      Object.keys(gebaeude).forEach((gebaeudeName) => {
        const geb = gebaeude[gebaeudeName];
        if (geb.isConstructing && geb.constructionEndTime && geb.constructionEndTime <= currentTime) {
          const gebLevel = typeof geb.level === 'number' && !isNaN(geb.level) ? geb.level : 0;
          const neuesLevel = gebLevel + 1;
          const gebaeudeInfo = getGebaeudeInfo(gebaeudeName);
          const neueProduktion = berechneProduktion(gebaeudeInfo, neuesLevel);
  
          // Berechnen Sie das neue maxArbeiter
          let neueMaxArbeiter = geb.maxArbeiter;
          if (gebaeudeInfo && typeof gebaeudeInfo.maxArbeiter === 'number') {
            neueMaxArbeiter = berechneMaxArbeiter(gebaeudeInfo, neuesLevel);
          }
  
          updatedGebaeude[gebaeudeName] = {
            ...geb,
            level: neuesLevel,
            produktion: isNaN(neueProduktion) ? 0 : neueProduktion,
            isConstructing: false,
            constructionEndTime: null,
            maxArbeiter: gebaeudeName === 'warehouse' ? 0 : neueMaxArbeiter,
            arbeiter: gebaeudeName === 'warehouse' ? 0 : geb.arbeiter || 0,
          };
  
          // Aktualisieren der Lagerkapazität, wenn das Lagerhaus ausgebaut wurde
          if (gebaeudeName === 'warehouse') {
            const neueKapazitaet = berechneLagerKapazitaet(gebaeudeInfo, neuesLevel);
            setLagerKapazitaet(neueKapazitaet);
          }
  
          updated = true;
          bauslotsFreigeben = true;
          addNotification(t('notification.upgradeCompleted', { gebaeude: t(`Gebaeude.${gebaeudeName}`), level: neuesLevel }), 'success');
        }
      });
  
      if (updated) {
        setGebaeude(updatedGebaeude);
      }
  
      if (bauslotsFreigeben) {
        setBauslots(prevBauslots => ({
          ...prevBauslots,
          available: prevBauslots.available + 1,
        }));
      }
    }, 1000);
  
    return () => clearInterval(interval);
  }, [gebaeude, setGebaeude, t, setBauslots]);
  

  // Definiere die Ressourcelabels mit Übersetzungen
  const resourceLabels = {
    holz: t('Rohstoffe.holz'),
    stein: t('Rohstoffe.stein'),
    nahrung: t('Rohstoffe.nahrung'),
    wasser: t('Rohstoffe.wasser'),
    kohle: t('Rohstoffe.kohle'),
    metall: t('Rohstoffe.metall'),
    gold: t('Rohstoffe.gold'),
    bevoelkerung: t('Rohstoffe.bevoelkerung'),
    hopfen: t('Rohstoffe.hops'),
    furniture: t('Rohstoffe.furniture'),
    beer: t('Rohstoffe.beer'), 
    bretter: t('Rohstoffe.bretter'),
  };

  return (
    <div>
      {/* Tabs Container */}
      <div className="tabs-container">
        {tabs.map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`tab-button ${activeTab === tab ? 'active' : ''}`}
          >
            {t(`GebaeudeTabs.${tab}`)}
          </button>
        ))}
      </div>

      {/* Gebäude Container */}
      <div className="gebaeude-container">
        {gebaeudeDaten[activeTab]
          .filter(gebaeudeInfo => isBuildingUnlocked(gebaeudeInfo))
          .map((gebaeudeInfo) => {
            const gebaeudeName = gebaeudeInfo.name; 
            const geb = gebaeude[gebaeudeName] || {};
            const currentLevel = typeof geb.level === 'number' ? geb.level : 1;
            const { kosten, populationCost } = berechneKosten(gebaeudeInfo, currentLevel + 1);
            const genugRessourcen = Object.keys(kosten).every(
              (ressource) => resources[ressource] >= kosten[ressource]
            ) && freePopulation >= populationCost;

            const isConstructing = geb.isConstructing;
            const remainingTime = isConstructing && geb.constructionEndTime
              ? Math.max(0, Math.ceil((geb.constructionEndTime - Date.now()) / 1000))
              : 0;

            return (
              <div
                key={gebaeudeName}
                className="gebaeude-item"
                style={{ backgroundImage: `url(${gebaeudeInfo.bild})` }}
              >
                <div className="gebaeude-info">
                  <h2>{t(`Gebaeude.${gebaeudeName}`)} ({t('Gebaeude.level')} {currentLevel})</h2>
                  <p>{gebaeudeInfo.beschreibungKey ? t(gebaeudeInfo.beschreibungKey) : t('Gebaeude.beschreibung_nicht_verfuegbar')}</p>
                  <p><strong>{t('Gebaeude.kostenBasis')}:</strong></p>
                  <ul>
                    {Object.keys(kosten).map((ressource) => (
                      <li key={ressource}>
                        {resourceLabels[ressource]}: {kosten[ressource]}
                      </li>
                    ))}
                    {populationCost > 0 && (
                      <li>
                        {t('Rohstoffe.bevoelkerung')}: {populationCost}
                      </li>
                    )}
                  </ul>
                  <p><strong>{t('Gebaeude.bauzeit')}:</strong> {formatTime(berechneBauzeit(gebaeudeInfo, currentLevel + 1))}</p>
                  {isConstructing ? (
                    <p style={{ color: 'orange' }}>{t('Gebaeude.buildTimeRemaining')}: {formatTime(remainingTime)}</p>
                  ) : (
                    <>
                      {gebaeudeInfo.produktionBasis && (
                        <p><strong>{t('Gebaeude.productionNextLevel')}:</strong> {berechneProduktion(gebaeudeInfo, currentLevel + 1)} {t('Gebaeude.perSecond')}</p>
                      )}
                      <button
                        onClick={() => handleUpgrade(gebaeudeName)}
                        disabled={
                          isConstructing ||
                          !genugRessourcen ||
                          freePopulation < populationCost ||
                          bauslots.available <= 0  // Überprüft die verfügbaren Bauslots
                        }
                      >
                        {geb.level > 0 ? t('Gebaeude.upgrade') : t('Gebaeude.build')}
                      </button>
                    </>
                  )}
                </div>
              </div>
          );
        })}
      </div>

      {/* Benachrichtigungen */}
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          message={notification.message}
          type={notification.type}
          onClose={() => setNotifications(prev => prev.filter(n => n.id !== notification.id))}
        />
      ))}
    </div>
  );
}

Gebaeude.propTypes = {
  resources: PropTypes.object.isRequired,
  setResources: PropTypes.func.isRequired,
  gebaeude: PropTypes.object.isRequired,
  setGebaeude: PropTypes.func.isRequired,
  lagerKapazitaet: PropTypes.object.isRequired,
  addRessourcenAusgabe: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  maxPopulation: PropTypes.number.isRequired,       
  usedPopulation: PropTypes.number.isRequired,
  setLagerKapazitaet: PropTypes.func.isRequired, 
  bauslots: PropTypes.object.isRequired,      // Hinzugefügt
  setBauslots: PropTypes.func.isRequired,     // Hinzugefügt
};

export default Gebaeude;
