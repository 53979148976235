// src/components/ResourceManager.js

// Hilfsfunktion, um Gebaeudeinformationen abzurufen
import { gebaeudeDaten } from './gebaeudeDaten';

const getGebaeudeInfo = (gebaeudeName) => {
  for (const category of Object.values(gebaeudeDaten)) {
    const gebInfo = category.find((g) => g.name === gebaeudeName);
    if (gebInfo) {
      return gebInfo;
    }
  }
  return null;
};

// Funktion zur Produktion von Ressourcen unter Berücksichtigung der Lagerkapazität und zugewiesener Arbeiter
export const produceResources = (gebaeude, lagerKapazitaet, currentResources) => {
  const neueRessourcen = { ...currentResources };

  Object.keys(gebaeude).forEach((gebaeudeName) => {
    const geb = gebaeude[gebaeudeName];
    if (gebaeudeName === 'house') return; // Haus produziert keine Ressourcen

    const gebaeudeInfo = getGebaeudeInfo(gebaeudeName);
    if (!gebaeudeInfo) {
      console.warn(`Gebäudeinformationen für ${gebaeudeName} nicht gefunden.`);
      return;
    }

    const arbeiter = geb.arbeiter || 0;
    if (arbeiter === 0) return; // Keine Arbeiter zugewiesen, keine Produktion

    const maxArbeiter = gebaeudeInfo.maxArbeiter || 1;
    const productionPerSecond = berechneProduktion(gebaeudeInfo, geb.level);
    const actualProduction = (arbeiter / maxArbeiter) * productionPerSecond;

    switch (gebaeudeName) {
      case 'lumberjack':
        neueRessourcen.holz = Math.min(neueRessourcen.holz + actualProduction, lagerKapazitaet.holz);
        break;
      case 'stonemason':
        neueRessourcen.stein = Math.min(neueRessourcen.stein + actualProduction, lagerKapazitaet.stein);
        break;
      case 'farm':
        neueRessourcen.nahrung = Math.min(neueRessourcen.nahrung + actualProduction, lagerKapazitaet.nahrung);
        break;
      case 'drawingWell':
        neueRessourcen.wasser = Math.min(neueRessourcen.wasser + actualProduction, lagerKapazitaet.wasser);
        break;
      case 'coalMine':
        neueRessourcen.kohle = Math.min(neueRessourcen.kohle + actualProduction, lagerKapazitaet.kohle);
        break;
      case 'erzmine':
        neueRessourcen.metall = Math.min(neueRessourcen.metall + actualProduction, lagerKapazitaet.metall);
        break;
        case 'bretterbude':
          // Verbrauch von Holz für die Produktion von Brettern
          const holzVerbrauch = actualProduction * 2; // Beispiel: 2 Holz pro Bretter
          if (neueRessourcen.holz >= holzVerbrauch) {
            neueRessourcen.holz -= holzVerbrauch;
            neueRessourcen.bretter = Math.min(
              (neueRessourcen.bretter || 0) + actualProduction,
              lagerKapazitaet.bretter
            );
          } else {
            console.warn('Nicht genügend Holz für die Produktion von Brettern.');
          }
          break;
        case 'furniture':
          // Verbrauch von Brettern für die Produktion von Möbeln
          if (neueRessourcen.bretter >= actualProduction) {
            neueRessourcen.bretter -= actualProduction;
            neueRessourcen.furniture = Math.min(
              (neueRessourcen.furniture || 0) + actualProduction,
              lagerKapazitaet.furniture
            );
          } else {
            console.warn('Nicht genügend Bretter für die Produktion von Möbeln.');
          }
          break;
        case 'brewary':
          // Verbrauch von Hopfen und Wasser für die Produktion von Bier
          const hopfenVerbrauch = actualProduction * 3; // Beispiel: 3 Hopfen pro Bier
          const wasserVerbrauch = actualProduction * 5; // Beispiel: 5 Wasser pro Bier
          if (neueRessourcen.hopfen >= hopfenVerbrauch && neueRessourcen.wasser >= wasserVerbrauch) {
            neueRessourcen.hopfen -= hopfenVerbrauch;
            neueRessourcen.wasser -= wasserVerbrauch;
            neueRessourcen.beer = Math.min(
              (neueRessourcen.beer || 0) + actualProduction,
              lagerKapazitaet.beer
            );
          } else {
            console.warn('Nicht genügend Ressourcen für die Produktion von Bier.');
          }
          break;
        case 'hopfenfarm':
          // Produktion von Hopfen
          neueRessourcen.hopfen = Math.min(
            (neueRessourcen.hopfen || 0) + actualProduction,
            lagerKapazitaet.hopfen
          );
          break;
        default:
          console.warn(`Unbekanntes Gebäude: ${gebaeudeName}`);
          break;
      }
    });

  return neueRessourcen;
};

// Ergänze deine Produktionsberechnungsfunktion, falls nötig
const berechneProduktion = (gebaeudeInfo, level) => {
  if (!gebaeudeInfo || typeof gebaeudeInfo.produktionBasis !== 'number') {
    return 0;
  }
  const faktor = gebaeudeInfo.faktorProduktion;
  const produktion = gebaeudeInfo.produktionBasis * Math.pow(1 + faktor, level - 1);
  return produktion;
};

// Funktion zur Konsumierung von Ressourcen durch die Bevölkerung
export const consumeResources = (currentResources, consumption) => {
  const updatedResources = { ...currentResources };

  for (const [ressource, konsum] of Object.entries(consumption)) {
    if (updatedResources.hasOwnProperty(ressource)) {
      updatedResources[ressource] -= konsum;
      if (updatedResources[ressource] < 0) {
        updatedResources[ressource] = 0; // Verhindert negative Ressourcen
      }
    }
  }

  return updatedResources;
};

export const calculateBuildingConsumption = (gebaeude) => {
  const consumptionPerSecond = {};

  Object.keys(gebaeude).forEach((gebaeudeName) => {
    const geb = gebaeude[gebaeudeName];
    const gebaeudeInfo = getGebaeudeInfo(gebaeudeName);

    if (
      geb &&
      gebaeudeInfo &&
      !geb.isConstructing &&
      geb.arbeiter > 0 &&
      gebaeudeInfo.verbrauch
    ) {
      const arbeiterAnteil = geb.arbeiter / geb.maxArbeiter;

      Object.keys(gebaeudeInfo.verbrauch).forEach((ressource) => {
        const verbrauchMenge = gebaeudeInfo.verbrauch[ressource] * arbeiterAnteil;
        consumptionPerSecond[ressource] = (consumptionPerSecond[ressource] || 0) + verbrauchMenge;
      });
    }
  });

  return consumptionPerSecond;
};