// src/components/LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css'; // Optional: Für Styling

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-switcher">
      <button onClick={() => changeLanguage('de')} className={`language-button ${i18n.language === 'de' ? 'active' : ''}`}>
        DE
      </button>
      <button onClick={() => changeLanguage('en')} className={`language-button ${i18n.language === 'en' ? 'active' : ''}`}>
        EN
      </button>
    </div>
  );
}

export default LanguageSwitcher;
