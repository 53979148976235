// src/components/Notification.js
import React, { useEffect } from 'react';
import './Notification.css';

function Notification({ message, type, onClose }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000); // Popup verschwindet nach 3 Sekunden

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`notification ${type}`}>
      <p>{message}</p>
      <button className="close-button" onClick={onClose}>&times;</button>
    </div>
  );
}

export default Notification;
