// src/components/Statistiken.js

import React from 'react';
import './Statistiken.css';
import { gebaeudeDaten } from './gebaeudeDaten'; 
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function Statistiken({ 
  resources, 
  lagerKapazitaet, 
  totalProduction, 
  bauslots, 
  wirtschaftPunkte, 
  militaerPunkte, 
  maxPopulation, 
  populationGrowthRate, 
  usedPopulation,
  gebaeude 
}) {
  const { t } = useTranslation();

  // Funktion zur Abrufung der Gebäudedaten
  const getGebaeudeInfo = (gebaeudeName) => {
    for (const category of Object.values(gebaeudeDaten)) {
      const gebInfo = category.find((g) => g.name === gebaeudeName);
      if (gebInfo) {
        return gebInfo;
      }
    }
    return null;
  };

  const resourceToBuildingMap = {
    holz: 'lumberjack',
    stein: 'stonemason',
    nahrung: 'farm',
    wasser: 'drawingWell',
    kohle: 'coalMine',
    metall: 'erzmine',
    bretter: 'bretterbude',
    hopfen: 'hopfenfarm',
    furniture: 'furniture',
    beer: 'brewery',
    // ... weitere Zuordnungen
  };

  // Verbrauch durch Bevölkerung pro Stunde
  const populationConsumptionPerHour = {
    nahrung: resources.population * 0.005 * 3600,
    wasser: resources.population * 0.0035 * 3600,
  };

  // Verbrauch durch Gebäude pro Stunde
  const gebaeudeVerbrauchPerHour = {};

  Object.keys(gebaeude).forEach((gebaeudeName) => {
    const geb = gebaeude[gebaeudeName];
    const gebaeudeInfo = getGebaeudeInfo(gebaeudeName);
    if (
      geb && gebaeudeInfo && geb.level >= 1 && !geb.isConstructing && geb.arbeiter > 0 && gebaeudeInfo.verbrauch
    ) {
      const arbeiterAnteil = geb.arbeiter / geb.maxArbeiter;
      Object.keys(gebaeudeInfo.verbrauch).forEach((ressource) => {
        const verbrauchMenge = gebaeudeInfo.verbrauch[ressource] * arbeiterAnteil * 3600; // Verbrauch pro Stunde
        gebaeudeVerbrauchPerHour[ressource] = (gebaeudeVerbrauchPerHour[ressource] || 0) + verbrauchMenge;
      });
    }
  });

  // Gesamter Verbrauch pro Stunde
  const totalConsumptionPerHour = { ...populationConsumptionPerHour };

  Object.keys(gebaeudeVerbrauchPerHour).forEach((ressource) => {
    totalConsumptionPerHour[ressource] = (totalConsumptionPerHour[ressource] || 0) + gebaeudeVerbrauchPerHour[ressource];
  });

  const resourceLabels = {
    holz: t('Rohstoffe.holz'),
    stein: t('Rohstoffe.stein'),
    nahrung: t('Rohstoffe.nahrung'),
    wasser: t('Rohstoffe.wasser'),
    kohle: t('Rohstoffe.kohle'),
    metall: t('Rohstoffe.metall'),
    gold: t('Rohstoffe.gold'),
    bevoelkerung: t('Rohstoffe.bevoelkerung'),
    bretter: t('Rohstoffe.bretter'),
    hopfen: t('Rohstoffe.hopfen'),
    furniture: t('Rohstoffe.furniture'),
    beer: t('Rohstoffe.beer'),
  };

  // Netto-Produktion berechnen
  const netProduction = {};

  Object.keys(totalProduction).forEach((ressource) => {
    const productionPerHour = (totalProduction[ressource] || 0) * 3600;
    const consumptionPerHour = totalConsumptionPerHour[ressource] || 0;
    netProduction[ressource] = (productionPerHour - consumptionPerHour).toFixed(0);
  });

  const resourcesWithProgress = ['nahrung', 'wasser', 'holz', 'stein', 'kohle', 'metall', 'gold', 'bretter', 'hopfen', 'furniture', 'beer'];

  // Berechne freie Bevölkerung
  const freePopulation = resources.population - usedPopulation;

  return (
    <div className="statistiken-container">
      <h2>{t('Statistiken.titel')}</h2>
      
      <div className="statistiken-grid">
        {/* Rohstoffproduktion */}
        <div className="statistiken-grid-item">
          <h3>{t('Statistiken.rohstoffproduktion')}</h3>
          <ul>
            {Object.keys(totalProduction).map((ressource) => {
              const gebaeudeName = resourceToBuildingMap[ressource];
              if (gebaeudeName) {
                const geb = gebaeude[gebaeudeName];
                if (!geb || geb.level < 1) {
                  return null; // Statistiken für diese Ressource nicht anzeigen
                }
              }

              return (
                <li key={ressource}>
                  {resourceLabels[ressource]}: {(totalProduction[ressource] * 3600).toFixed(0)} {t('Statistiken.pro_stunde')}
                </li>
              );
            }).filter(item => item !== null)}
          </ul>
        </div>

        {/* Verbrauch durch Bevölkerung und Gebäude */}
        <div className="statistiken-grid-item">
          <h3>{t('Statistiken.verbrauch')}</h3>
          <ul>
            {Object.keys(totalConsumptionPerHour).map(ressource => (
              <li key={ressource}>
                {resourceLabels[ressource]}: {totalConsumptionPerHour[ressource].toFixed(0)} {t('Statistiken.pro_stunde')}
              </li>
            ))}
          </ul>
        </div>

        {/* Netto-Rohstoffproduktion */}
        <div className="statistiken-grid-item">
          <h3>{t('Statistiken.netto_rohstoffproduktion')}</h3>
          <ul>
            {/* Ressourcen mit Fortschrittsbalken */}
            {resourcesWithProgress.map((ressource) => {
              const gebaeudeName = resourceToBuildingMap[ressource];
              if (gebaeudeName) {
                const geb = gebaeude[gebaeudeName];
                if (!geb || geb.level < 1) {
                  return null; // Statistiken für diese Ressource nicht anzeigen
                }
              }

              const totalProductionPerHour = (totalProduction[ressource] || 0) * 3600;
              const netProd = netProduction[ressource];
              const progressWidth =
                totalProductionPerHour !== 0
                  ? ((netProd / totalProductionPerHour) * 100).toFixed(2)
                  : 0;

              return (
                <li key={ressource}>
                  {resourceLabels[ressource]}: {netProd} {t('Statistiken.pro_stunde')}
                  <div className="progress-bar">
                    <div
                      className="progress"
                      style={{
                        width: `${progressWidth}%`,
                      }}
                    ></div>
                  </div>
                </li>
              );
            }).filter(item => item !== null)}

            {/* Andere Ressourcen ohne Fortschrittsbalken */}
            {Object.keys(netProduction).map((ressource) => {
              if (resourcesWithProgress.includes(ressource)) return null;
              const gebaeudeName = resourceToBuildingMap[ressource];
              if (gebaeudeName) {
                const geb = gebaeude[gebaeudeName];
                if (!geb || geb.level < 1) {
                  return null; // Statistiken für diese Ressource nicht anzeigen
                }
              }

              return (
                <li key={ressource}>
                  {resourceLabels[ressource]}: {netProduction[ressource]} {t('Statistiken.pro_stunde')}
                </li>
              );
            }).filter(item => item !== null)}
          </ul>
        </div>

        {/* Lagerkapazitäten */}
        <div className="statistiken-grid-item">
          <h3>{t('Statistiken.lagerkapazitaeten')}</h3>
          <ul>
            {Object.keys(lagerKapazitaet).map(ressource => {
              const gebaeudeName = resourceToBuildingMap[ressource];
              if (gebaeudeName) {
                const geb = gebaeude[gebaeudeName];
                if (!geb || geb.level < 1) {
                  return null;
                }
              }

              return (
                <li key={ressource}>
                  {resourceLabels[ressource]}: {resources[ressource].toFixed(0)} / {lagerKapazitaet[ressource].toFixed(0)}
                </li>
              );
            }).filter(item => item !== null)}
          </ul>
        </div>

        {/* Bevölkerung */}
        <div className="statistiken-grid-item">
          <h3>{t('Statistiken.bevoelkerung')}</h3>
          <p>{t('Statistiken.aktuelle_bevoelkerung')}: {resources.population.toFixed(0)}</p>
          <p>{t('Statistiken.maximale_bevoelkerungskapazitaet')}: {maxPopulation}</p>
          <p>{t('Statistiken.bevoelkerungswachstum')}: {(populationGrowthRate * 3600).toFixed(0)} {t('Statistiken.pro_stunde')}</p>
          <p>{t('Statistiken.genutzte_bevoelkerung')}: {usedPopulation}</p>
          <p>{t('Statistiken.freie_bevoelkerung')}: {freePopulation}</p>
        </div>

        {/* Bauslots */}
        <div className="statistiken-grid-item">
          <h3>{t('Statistiken.bauslots')}</h3>
          <p>{bauslots.available} / {bauslots.total} {t('Statistiken.bauslots_verfuegbar')}</p>
        </div>

        {/* Punkte */}
        <div className="statistiken-grid-item">
          <h3>{t('Statistiken.punkte')}</h3>
          <p>{t('Statistiken.wirtschaftPunkte')}: {wirtschaftPunkte}</p> 
          <p>{t('Statistiken.militaerPunkte')}: {militaerPunkte}</p> 
        </div>
      </div>
    </div>
  );
}

Statistiken.propTypes = {
  resources: PropTypes.object.isRequired,
  lagerKapazitaet: PropTypes.object.isRequired,
  totalProduction: PropTypes.object.isRequired,
  bauslots: PropTypes.object.isRequired,
  wirtschaftPunkte: PropTypes.number.isRequired, 
  militaerPunkte: PropTypes.number.isRequired,   
  maxPopulation: PropTypes.number.isRequired,
  populationGrowthRate: PropTypes.number.isRequired,
  usedPopulation: PropTypes.number.isRequired,
  gebaeude: PropTypes.object.isRequired, 
};

export default Statistiken;
