// src/components/PopulationConsumption.js

export const consumptionRates = {
    nahrung: 0.0025, // Nahrungskonsum pro Bevölkerungseinheit pro Sekunde
    wasser: 0.0015,  // Wasserkonsum pro Bevölkerungseinheit pro Sekunde
    // Weitere Verbrauchsraten für später
  };
  
  export const calculateConsumption = (population) => {
    const consumption = {};
    for (const [resource, rate] of Object.entries(consumptionRates)) {
      consumption[resource] = population * rate;
    }
    return consumption;
  };
  /*testen ob die populationConsumtion noch relevant istda in der Game.js drin ist?? */