// src/components/LoadGame.js
import Web3 from 'web3';
import { CONTRACT_ADDRESS, CONTRACT_ABI } from '../wallet/contractData';

export const loadGame = async (walletAddress) => {
  try {
    if (window.ethereum) {
      console.log('Ethereum Provider gefunden.');
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const web3 = new Web3(window.ethereum);
      console.log('Web3 Instanz erstellt.');

      const gamePaymentContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
      console.log('Smart Contract instanziiert:', gamePaymentContract.options.address);

      // Abrufen der gespeicherten Ressourcen
      console.log('Lade gespeicherte Ressourcen vom Smart Contract...');
      const resourceData = await gamePaymentContract.methods.loadPlayerResources(walletAddress).call();
      console.log('Gespeicherte Ressourcen:', resourceData);

      // Abrufen der gespeicherten Gebäude
      console.log('Lade gespeicherte Gebäude vom Smart Contract...');
      const gebaeudeData = await gamePaymentContract.methods.loadPlayerBuildings(walletAddress).call();
      console.log('Gespeicherte Gebäude:', gebaeudeData);

      // Abrufen der allgemeinen Spielerstatistiken
      console.log('Lade gespeicherte allgemeine Spielerstatistiken vom Smart Contract...');
      const generalStats = await gamePaymentContract.methods.loadPlayerGeneralStats(walletAddress).call();
      console.log('Gespeicherte allgemeine Spielerstatistiken:', generalStats);

      // Abrufen der bevölkerungsbezogenen Statistiken
      console.log('Lade gespeicherte bevölkerungsbezogene Statistiken vom Smart Contract...');
      const populationStats = await gamePaymentContract.methods.loadPlayerPopulationStats(walletAddress).call();
      console.log('Gespeicherte bevölkerungsbezogene Statistiken:', populationStats);

      // Lade die Bauslots
      const bauslots = await gamePaymentContract.methods.loadPlayerBauslots(walletAddress).call();
      console.log('Geladene Bauslots:', bauslots);

      // Skalierungsfaktor für 2 Dezimalstellen
      const SCALING_FACTOR = 100;

      // Strukturieren der Ressourcen
      const resources = {};
      resourceData.resourceKeys.forEach((key, index) => {
        resources[key] = Number(resourceData.resourceValues[index]) / SCALING_FACTOR; // Konvertiere zurück zu Dezimalzahl
      });

      // Strukturieren der Gebäude
      const gebaeude = {};
      gebaeudeData.gebaeudeKeys.forEach((key, index) => {
        const level = Number(gebaeudeData.gebaeudeLevels[index]);
        if (level > 0) {
          gebaeude[key] = {
            level: level,
            isConstructing: gebaeudeData.gebaeudeIsConstructing[index],
            constructionEndTime: Number(gebaeudeData.gebaeudeConstructionEndTimes[index]) * 1000, // Konvertiere zurück zu Millisekunden
            produktion: Number(gebaeudeData.gebaeudeProduktionen[index]) / SCALING_FACTOR, // Konvertiere zurück zu Dezimalzahl
            maxArbeiter: key === 'warehouse' ? 0 : Number(gebaeudeData.gebaeudeMaxArbeiter[index]), // Für warehouse auf 0 setzen
            arbeiter: key === 'warehouse' ? 0 : Number(gebaeudeData.gebaeudeArbeiter[index]),
          };
        }
      });

      // Anzahl der aktiven Bauten berechnen
      let anzahlAktiverBauten = 0;
      for (const gebKey in gebaeude) {
        if (gebaeude.hasOwnProperty(gebKey)) {
          const geb = gebaeude[gebKey];
          if (geb.isConstructing) {
            anzahlAktiverBauten += 1;
          }
        }
      }

      // Strukturieren der allgemeinen Statistiken
      const punkte = Number(generalStats.punkte);
      const resourcenAusgegeben = Number(generalStats.resourcenAusgegeben);
      const lastLogin = new Date(Number(generalStats.lastLogin) * 1000); 
      const wirtschaftPunkte = Number(generalStats.wirtschaftPunkte); 
      const militaerPunkte = Number(generalStats.militaerPunkte);     
      const resourcenAusgegebenWirtschaft = Number(generalStats.resourcenAusgegebenWirtschaft); 
      const resourcenAusgegebenMilitaer = Number(generalStats.resourcenAusgegebenMilitaer);     
      // Strukturieren der bevölkerungsbezogenen Statistiken
      const POPULATION_SCALING_FACTOR = 100;
      const GROWTH_RATE_SCALING_FACTOR = 1000;
      const aktuelleBevoelkerung = Number(populationStats.aktuelleBevoelkerung) / POPULATION_SCALING_FACTOR;
      const genutzteBevoelkerung = Number(populationStats.genutzteBevoelkerung) / POPULATION_SCALING_FACTOR;
      const maximaleBevoelkerungskapazitaet = Number(populationStats.maximaleBevoelkerungskapazitaet) / POPULATION_SCALING_FACTOR;
      const bevoelkerungswachstumProStunde = Number(populationStats.bevoelkerungswachstumProStunde) / GROWTH_RATE_SCALING_FACTOR;

      // Konvertiere bauslots zu einer Zahl
      const bauslotsTotal = Number(bauslots);
      const bauslotsAvailable = bauslotsTotal - anzahlAktiverBauten;

      console.log('Geladene Spieldaten:', {
        resources,
        gebaeude,
        punkte,
        resourcenAusgegeben,
        lastLogin,
        aktuelleBevoelkerung,
        genutzteBevoelkerung,
        maximaleBevoelkerungskapazitaet,
        bevoelkerungswachstumProStunde,
        bauslotsTotal,
        bauslotsAvailable,
      });

      return {
        success: true,
        data: {
          resources,
          gebaeude,
          punkte,
          resourcenAusgegeben,
          wirtschaftPunkte, 
          militaerPunkte,   
          resourcenAusgegebenWirtschaft, 
          resourcenAusgegebenMilitaer,  
          lastLogin,
          aktuelleBevoelkerung,
          genutzteBevoelkerung,
          maximaleBevoelkerungskapazitaet,
          bevoelkerungswachstumProStunde,
          bauslots: {
            total: bauslotsTotal,
            available: bauslotsAvailable,
          },
        },
      };
    } else {
      throw new Error('Kein Ethereum-Provider gefunden');
    }
  } catch (error) {
    console.error('Fehler beim Laden des Spiels:', error);
    if (error.data) {
      console.error('Fehlerdaten:', error.data);
    }
    if (error.stack) {
      console.error('Fehler-Stack:', error.stack);
    }
    alert(`Fehler beim Laden des Spiels: ${error.message}`);
    return { success: false, message: error.message };
  }
};
