// src/components/Shop.js

import React, { useState } from 'react';
import './Statistiken.css'; // Verwende die gleichen Styles wie in Statistiken
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { buyBauslot } from './BuyBauslot'; // Funktion zum Kauf eines Bauslots

function Shop({ bauslots, setBauslots, walletAddress }) {
  const { t } = useTranslation();
  const [isBuying, setIsBuying] = useState(false);
  const [notification, setNotification] = useState(null);

  const handleBuyBauslot = async () => {
    setIsBuying(true);
    try {
      const result = await buyBauslot(walletAddress);
      if (result.success) {
        // Erhöhe die Bauslots im Spielzustand
        setBauslots(prevBauslots => ({
          ...prevBauslots,
          total: prevBauslots.total + 1,
          available: prevBauslots.available + 1,
        }));
        setNotification({ type: 'success', message: t('Shop.success') });
      } else {
        setNotification({ type: 'error', message: result.message || t('Shop.error') });
      }
    } catch (error) {
      console.error('Fehler beim Kauf des Bauslots:', error);
      setNotification({ type: 'error', message: t('Shop.error') });
    } finally {
      setIsBuying(false);
    }
  };

  return (
    <div className="statistiken-container">
      <h2>{t('Shop.title')}</h2>
      <div className="statistiken-grid">
        <div className="statistiken-grid-item">
          <h3>{t('Shop.bauslot_title')}</h3>
          <p>{t('Shop.bauslot_description')}</p>
          <button onClick={handleBuyBauslot} disabled={isBuying}>
            {isBuying ? t('Shop.buying') : t('Shop.buy_button')}
          </button>
          {notification && (
            <div className={`notification ${notification.type}`}>
              <p>{notification.message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Shop.propTypes = {
  bauslots: PropTypes.object.isRequired,
  setBauslots: PropTypes.func.isRequired,
  walletAddress: PropTypes.string.isRequired,
};

export default Shop;
