// src/components/Arbeiter.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import './Arbeiter.css';
import { gebaeudeDaten } from './gebaeudeDaten';

function Arbeiter({ gebaeude, setGebaeude, resources, showNotification }) {
  const { t } = useTranslation();

  // Defensive Programmierung: Überprüfen, ob resources und resources.population definiert sind
  if (!resources || typeof resources.population === 'undefined') {
    return <div>{t('Arbeiter.loading')}</div>; 
  }

  // Funktion zur Berechnung der genutzten Bevölkerung
  const berechneUsedPopulation = (gebaeude) => {
    let usedPopulation = 0;
    Object.keys(gebaeude).forEach((gebaeudeName) => {
      const geb = gebaeude[gebaeudeName];
      if (geb && geb.arbeiter) {
        usedPopulation += geb.arbeiter;
      }
    });
    return usedPopulation;
  };

  // Berechnen der genutzten und verfügbaren Bevölkerung
  const usedPopulation = berechneUsedPopulation(gebaeude);
  const availablePopulation = resources.population - usedPopulation;

  // Berechne die insgesamt genutzte Bevölkerung
  const totalUsedWorkers = Object.values(gebaeude).reduce((acc, geb) => acc + (geb.arbeiter || 0), 0);

  // Funktion zur Abrufung der Gebäudedaten
  const getGebaeudeInfo = (gebaeudeName) => {
    for (const category of Object.values(gebaeudeDaten)) {
      const gebInfo = category.find(g => g.name === gebaeudeName);
      if (gebInfo) {
        return gebInfo;
      }
    }
    return null;
  };

  const handleAssign = (gebaeudeName, increment) => {
    const gebInfo = getGebaeudeInfo(gebaeudeName);
    if (!gebaeudeName || !gebaeude[gebaeudeName] || !gebInfo) return;

    const geb = gebaeude[gebaeudeName];
    const maxArbeiter = geb.maxArbeiter;
    const currentArbeiter = geb.arbeiter || 0;
    let newArbeiter = currentArbeiter + increment;

    // Stelle sicher, dass die Arbeiterzahl nicht negativ wird
    if (newArbeiter < 0) {
      newArbeiter = 0;
      showNotification(t('alert.invalidWorkerCount'), 'error'); // Optional: Füge diesen Übersetzungsschlüssel hinzu
      return;
    }

    // Überprüfe die maximale Anzahl der Arbeiter
    if (newArbeiter > maxArbeiter) {
      showNotification(t('alert.tooManyWorkers'), 'error');
      newArbeiter = maxArbeiter;
      return;
    }

    // Berechne die neue Gesamtzahl der genutzten Arbeiter
    const newTotalUsedWorkers = totalUsedWorkers - currentArbeiter + newArbeiter;

    // Überprüfe, ob genügend freie Bevölkerung vorhanden ist
    if (newTotalUsedWorkers > resources.population) {
      showNotification(t('alert.notEnoughPopulation'), 'error');
      return;
    }

    // Aktualisiere die Arbeiterzahl für das Gebäude
    setGebaeude(prevGebaeude => ({
      ...prevGebaeude,
      [gebaeudeName]: {
        ...prevGebaeude[gebaeudeName],
        arbeiter: newArbeiter,
      },
    }));

    // Optionale Benachrichtigung bei erfolgreicher Zuweisung
    if (increment > 0) {
      showNotification(t('notification.workerAssigned', { gebaeude: t(`Gebaeude.${gebaeudeName}`) }), 'success');
    } else {
      showNotification(t('notification.workerRemoved', { gebaeude: t(`Gebaeude.${gebaeudeName}`) }), 'success');
    }
  };

  const excludedBuildings = ['house', 'warehouse', 'market', 'barracks', 'fort', 'marine'];

  // Filtere die gebauten Gebäude, die nicht im Bau sind und eine maxArbeiter-Eigenschaft haben
  const builtBuildings = Object.keys(gebaeude).filter(name => {
    const geb = gebaeude[name];
    const isBuilt = geb.level >= 1 && !geb.isConstructing;
    const notConstructing = !geb.isConstructing;
    const hasMaxArbeiter = geb && typeof geb.maxArbeiter !== 'undefined';
    const isExcluded = excludedBuildings.includes(name);
    //console.log(`Gebäude: ${name}, Level: ${geb.level}, IsConstructing: ${geb.isConstructing}, MaxArbeiter: ${geb.maxArbeiter}, Wird angezeigt: ${isBuilt && notConstructing && hasMaxArbeiter}`);
    return isBuilt && notConstructing && hasMaxArbeiter && !isExcluded;
  });

  //console.log('Gebäude, die in der Arbeiter-Seite angezeigt werden:', builtBuildings);

  return (
    <div className="arbeiter-container">
      <h2>{t('Arbeiter.title')}</h2>
      {builtBuildings.length === 0 ? (
        <p>{t('Arbeiter.noBuiltBuildings')}</p>
      ) : (
        builtBuildings.map(gebaeudeName => {
          const geb = gebaeude[gebaeudeName];
          if (!gebaeudeName || !geb) return null;

          const currentArbeiter = geb.arbeiter || 0;
          const maxArbeiter = geb.maxArbeiter;
          const availablePopulation = resources.population - usedPopulation;

          return (
            <div key={gebaeudeName} className="arbeiter-item">
              <span className="gebaeude-name">{t(`Gebaeude.${gebaeudeName}`)}</span>
              <div className="worker-controls">
                <button
                  onClick={() => handleAssign(gebaeudeName, -1)}
                  disabled={currentArbeiter <= 0}
                >
                  -
                </button>
                <span>{currentArbeiter} / {maxArbeiter}</span>
                <button
                  onClick={() => handleAssign(gebaeudeName, 1)}
                  disabled={currentArbeiter >= maxArbeiter || availablePopulation <= 0}
                >
                  +
                </button>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default Arbeiter;
